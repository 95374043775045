const mutations = {
    setPortes: (state, payload) => {
        state.portes = payload;
    },
    setPorte: (state, payload) => {
        state.porte = payload;
    },
    setPorteApplication: (state, payload) => {
        state.porteApplication = payload;
    },
    setPorteComposant: (state, payload) => {
        state.porteComposant = payload;
    }

};
export default mutations;