import { httpClient } from "@/helpers/services/HttpClient";
import { PHASE_URL } from '@/helpers/constURL'

const actions = {
    fetchAllPhases: async ({ commit }) => {
        const response = await httpClient.get(PHASE_URL)
        const data = response.data['hydra:member'];
        commit('setPhases', data);
    },
    createPhase: async ({ commit }, data) => {
        await httpClient.post(PHASE_URL, data).then((response) => {
            commit('setPhase', response.data)
        }).catch(error => error)
    },
    deletePhase: async ({ commit }, id) => {
        await httpClient.delete(`${PHASE_URL}/${id}`)
            .catch(error => error)
        commit

    },
    fetchPhase: async ({ commit }, id) => {
        await httpClient.get(`${PHASE_URL}/${id}`).then(
            (response) =>
                commit('setPhase', response.data)
        )
            .catch(error => error)

    },
    updatePhase: async ({ commit }, data) => {
        await httpClient.put(`${PHASE_URL}/${data.id}`, data)
            .then((response) => {
                commit('setPhase', response.data)

            }).catch(error => error)
    },


};

export default actions;