import {httpClient} from "../../../../helpers/services/HttpClient";

const actions = {
    fetchAllPlans: async ({commit}) => {
        const res = await httpClient.get('gouvernance/plannings').catch(err => window.console.log(err));
        const data = res.data['hydra:member'];
        window.console.log(data , "data");
        commit('setPlannings', data);

    },

    createPlanning:async ({commit}, data) => {
        const res = await httpClient.post('gouvernance/plannings', data)
            .catch(error => error)
        window.console.log(data , "DATA")
        window.console.log(commit)
        window.console.log(res)

    },
    updatePlanning: async ({ commit }, data) => {
        window.console.log(data,'updaetmembers')
        const response = await httpClient.put(`gouvernance/plannings/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deletePlanning: async ({ commit }, id) => {
        const response = await httpClient.delete(`gouvernance/plannings/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)

    },

    fetchinscription: async ({ commit }, id) => {
        const response = await httpClient.get(`gouvernance/plannings/${id}/dossiers`)
            .catch(error => error)
        window.console.log(response.data,"DATA INSCRIPTION")
        commit('setInscription', response.data)

    },
    fetchPlannings: async ({ commit }, id) => {
        const response = await httpClient.get(`gouvernance/plannings/${id}`)
            .catch(error => error)
        window.console.log(response.data,"DATA INSCRIPTION")
        commit('setPlanning', response.data)

    },
}

export default actions;