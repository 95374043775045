import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { IMPUTATION_URL } from '@/helpers/constURL'

const actions = {
    fetchAllImputations: async ({ commit }, page = IMPUTATION_URL) => {
        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];

        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${IMPUTATION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setImputations', allData);

    },
    fetchAllImputationsTasks: async ({ commit }) => {
        const response = await httpClient.get(IMPUTATION_URL)
        const totalItems = response.data['hydra:totalItems'];
        const data = response.data['hydra:member'];

        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`transformation/tasks/imputations?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(totalItems)
        window.console.log(allData)
        commit('setImputationsTasks', data);

    },
    deleteImputation: async ({ commit }, id) => {
        const response = await httpClient.delete(`${IMPUTATION_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    createImputation: async ({ commit }, data) => {
        const response = await httpClient.post(IMPUTATION_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        // window.console.log(response)
        commit('setImputation', response.data)

        return response.data;

    },
    fetchImputation: async ({ commit }, id) => {
        const response = await httpClient.get(`${IMPUTATION_URL}/${id}`)
            .catch(error => error)
        commit('setImputation', response.data)
        window.console.log(response.data)

    },
    updateImputation: async ({ commit }, data) => {
        const response = await httpClient.put(`${IMPUTATION_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setImputation', response.data)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    fetchTeamImputations: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${IMPUTATION_URL}/teams?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];

                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setTeamImputations', allData);

    },


};

export default actions;