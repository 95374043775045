import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { REF_PHASE_URL, } from "@/helpers/constURL";
const actions = {
    fetchAllRefPhases: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${REF_PHASE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setRefPhases', allData);
    },
    createRefPhase: async ({ commit }, data) => {
        await httpClient.post(REF_PHASE_URL, data).then((response) => {
            commit('setRefPhase', response.data)

        }).catch(error => error)
    },
    deleteRefPhase: async ({ commit }, id) => {
        await httpClient.delete(`${REF_PHASE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchRefPhase: async ({ commit }, id) => {
        await httpClient.get(`${REF_PHASE_URL}/${id}`).then(
            (response) =>
                commit('setRefPhase', response.data)
        )
            .catch(error => error)

    },
    updateRefPhase: async ({ commit }, data) => {
        await httpClient.put(`${REF_PHASE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setRefPhase', response.data)

            }).catch(error => error)
    },
};

export default actions;