import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import {
    STATUS_APPLICATION_URL,
    STATUS_COMITE_URL,
    STATUS_COMPOSANT_URL,
    STATUS_CONTRIBUTEUR_URL,
    STATUS_FEUILLE_URL,
    STATUS_INITIATIVE_URL,
    STATUS_INSCRIPTION_URL,
    STATUS_PHASE_URL,
    STATUS_REF_CARD_URL,
    STATUS_SEMAINE_URL,
    STATUS_SESSION_URL,
    STATUS_TASK_URL,
    STATUS_VISION_URL
} from "@/helpers/constURL";

export function getUrlByType(type) {
    let url = "";
    switch (type) {
        case "Application":
            url = STATUS_APPLICATION_URL;
            break;
        case "Comite":
            url = STATUS_COMITE_URL;
            break;
        case "Composant":
            url = STATUS_COMPOSANT_URL;
            break;
        case "Contributeur":
            url = STATUS_CONTRIBUTEUR_URL;
            break;
        case "Feuille":
            url = STATUS_FEUILLE_URL;
            break;
        case "Initiative":
            url = STATUS_INITIATIVE_URL;
            break;
        case "Inscription":
            url = STATUS_INSCRIPTION_URL;
            break;
        case "Phase":
            url = STATUS_PHASE_URL;
            break;
        case "Refcard":
            url = STATUS_REF_CARD_URL;
            break;
        case "Semaine":
            url = STATUS_SEMAINE_URL;
            break;
        case "Session":
            url = STATUS_SESSION_URL;
            break;
        case "Task":
            url = STATUS_TASK_URL;
            break;
        case "Vision":
            url = STATUS_VISION_URL;
            break;
        default:
            break;

    }

    return url;

}

export async function getAllStatusByType(url, commit, mutation) {
    var totalItems = 0;
    var allData = []
    for (let i = 1; i <= (totalItems / 10) + 1; i++) {
        await httpClient.get(`${url}?page=${i}`).then(({ data }) => {
            totalItems = data['hydra:totalItems'];
            allData = concat(allData, data['hydra:member'].map(({ status }) => status));
        }).catch(error => {
            window.console.log(error);
        });

    }
    commit(mutation, allData);
}

