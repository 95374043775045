const mutations = {
    setTypes: (state, payload) => {
        state.types = payload;
    },
    setType: (state, payload) => {
        state.type = payload;
    },
    setGainTypes: (state, payload) => {
        state.gains = payload;
    },
    setGainType: (state, payload) => {
        state.gain = payload;
    },
    setApplicationTypes: (state, payload) => {
        state.applicationTypes = payload;
    },
    setApplicationType: (state, payload) => {
        state.applicationType = payload;
    },
    setComposantTypes: (state, payload) => {
        state.composantTypes = payload;
    },
    setComposantType: (state, payload) => {
        state.composantType = payload;
    },
    setComiteTypes: (state, payload) => {
        state.comiteTypes = payload;
    },
    setComiteType: (state, payload) => {
        state.comiteType = payload;
    },
    setContributeurTypes: (state, payload) => {
        state.contributeurTypes = payload;
    },
    setContributeurType: (state, payload) => {
        state.contributeurType = payload;
    },
    setFeuilleTypes: (state, payload) => {
        state.feuilleTypes = payload;
    },
    setFeuilleType: (state, payload) => {
        state.feuilleType = payload;
    },
    setInitiativeTypes: (state, payload) => {
        state.initiativeTypes = payload;
    },
    setInitiativeType: (state, payload) => {
        state.initiativeType = payload;
    },
    setTaskTypes: (state, payload) => {
        state.taskTypes = payload;
    },
    setTaskType: (state, payload) => {
        state.taskType = payload;
    },
    setRefCardTypes: (state, payload) => {
        state.refCardTypes = payload;
    },
    setRefCardType: (state, payload) => {
        state.refCardType = payload;
    },
    setKpiTypes: (state, payload) => {
        state.kpiTypes = payload;
    },
    setKpiType: (state, payload) => {
        state.kpiType = payload;
    },



};
export default mutations;