import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {


    fetchAllConcepts: async ({ commit }, page = 'referentiel/concepts') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/concepts?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setConcepts', allData);
    },
    createConcept: async ({ commit }, data) => {
        await httpClient.post('referentiel/concepts', data).then((response) => {
            commit('setConcept', response.data)
        }).catch(error => error)
    },
    deleteConcept: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/concepts/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchConcept: async ({ commit }, id) => {
        await httpClient.get(`referentiel/concepts/${id}`).then(
            (response) =>
                commit('setConcept', response.data)

        )
            .catch(error => error)

    },
    updateConcept: async ({ commit }, data) => {
        await httpClient.put(`referentiel/concepts/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;