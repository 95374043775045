import { httpClient } from "../../../helpers/services/HttpClient";

const state = {
    types: [],
    initiativeTypes: [],
    impactTypes: [],
    fdrTypes: [],
    taskTypes: [],
    OrganisationTypes :[],
    beneficiaireTypes: []

}
const getters = {
    TYPES: state => state.types,
    IMPACT_TYPES: state => state.impactTypes,
    FDR_TYPES: state => state.fdrTypes,
    INITIATIVE_TYPES: state => state.initiativeTypes,
    TASK_TYPES: state => state.taskTypes,
    ORG_TYPES : state => state.OrganisationTypes,
    BENEFICIAIRE_TYPES: state => state.beneficiaireTypes,
    // ORGANISATION_TYPES: state => state.OrganisationTypes
}

const mutations = {
    setTypes: (state, payload) => {
        state.status = payload;
    },
    setImpactTypes: (state, payload) => {
        state.impactTypes = payload;
    },
    setOrganisationTypes: (state, payload) => {
        state.OrganisationTypes = payload;
    },
    setFdrTypes: (state, payload) => {
        state.fdrTypes = payload
    },
    setInitiativeTypes: (state, payload) => {
        state.initiativeTypes = payload
    },
    setTaskTypes: (state, payload) => {
        state.taskTypes = payload
    } ,
    setBeneficiaireTypes: (state, payload) => {
        state.beneficiaireTypes = payload
    }

}

const actions = {
    fetchAllTypes: async ({ commit, state }) => {
        if (state.types.length) return;
        const res = await httpClient.get('/types').catch(err => window.console.log(err));
        window.console.log(res.data);
        commit('setTypes', res.data.type);
    },
    fetchImpactTypes: async ({ commit }) => {
        if (state.impactTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.impacts;
        commit('setImpactTypes', data)

    },
    fetchOrganisationTypes: async ({ commit }) => {
        if (state.impactTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.organisation;
        window.console.log(data , 'org')
        commit('setOrganisationTypes', data)

    },
    fetchFdrTypes: async ({ commit }) => {
        if (state.fdrTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.fdr;
        commit('setFdrTypes', data)

    },
    fetchInitiativeTypes: async ({ commit }) => {
        if (state.initiativeTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.initiatives;
        commit('setInitiativeTypes', data)

    },
    fetchTaskTypes: async ({ commit }) => {
        if (state.taskTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.tasks; 
        commit('setTaskTypes', data)

    },
    fetchBeneficiaireTypes: async ({ commit }) => {
        if (state.beneficiaireTypes.length) return;
        const response = await httpClient.get('/types').catch(error => window.console.log(error))
        const data = response.data.type.beneficiaire;
        commit('setBeneficiaireTypes', data)

    },
    
}

const TypeModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default TypeModule;