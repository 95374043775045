var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-sidebar bg-white",staticStyle:{"position":"relative","z-index":"1000"}},[_c('nav',{staticClass:"sidebar sidebar-offcanvas bg-white",attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"nav bg-white"},[_c('div',{staticClass:"nv bg-white",staticStyle:{"min-height":"calc(100vh - 70px) !important"}},_vm._l((_vm.routes),function(route,index){return _c('li',{key:index + 'list_itm',staticClass:"nav-item"},[(route.meta.title === 'Administration')?_c('hr'):_vm._e(),(route.hasOwnProperty('children'))?_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(route.name),expression:"route.name"}],class:{
              'nav-link': true,
              'matched-link': _vm.matchedLink(route),
            }},[_c('span',{staticClass:"menu-title border-0 text-truncate"},[_vm._v(_vm._s(route.meta.title))]),_c('i',{staticClass:"menu-arrow"}),_c('i',{class:[
                `mdi menu-icon ${route.meta.iconClass}`,
                _vm.matchedLink(route) ? 'text-primary' : 'text-secondary',
              ]})]):_vm._e(),(!route.hasOwnProperty('children'))?_c('router-link',{staticClass:"nav-link",attrs:{"to":route.path},nativeOn:{"click":function($event){return _vm.hideMobileSidebar()}}},[_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(route.meta.title))]),_c('i',{class:[
                `mdi menu-icon ${route.meta.iconClass}`,
                route.name === _vm.$route.name ? 'text-primary' : 'text-secondary',
              ]})]):_vm._e(),(route.hasOwnProperty('children'))?_c('b-collapse',{attrs:{"accordion":"sidebar-accordion","id":route.name}},[_c('ul',{staticClass:"nav flex-column sub-menu nav-itemo"},_vm._l((route.children),function(childRoute,ind){return _c('li',{key:ind + ' li na uk',staticClass:"nav-item"},[_c('router-link',{class:{
                    'nav-link': true,
                    active: _vm.matchedLink(childRoute),
                  },attrs:{"to":route.path != '/'
                      ? route.path + '/' + childRoute.path
                      : route.path + childRoute.path},nativeOn:{"click":function($event){return _vm.hideMobileSidebar()}}},[_vm._v(_vm._s(childRoute.meta.title))])],1)}),0)]):_vm._e()],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }