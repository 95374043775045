import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash"
import { INSCRIPTION_URL } from '@/helpers/constURL'

const actions = {
    fetchAllInscriptions: async ({ commit }) => {
        const response = await httpClient.get(INSCRIPTION_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${INSCRIPTION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        window.console.log(allData)
        commit('setInscriptions', allData);
    },
    updateInscription: async ({ commit }, data) => {
        window.console.log(data, 'updaetmembers')
        const response = await httpClient.put(`${INSCRIPTION_URL}/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteInscription: async ({ commit }, id) => {
        const response = await httpClient.delete(`${INSCRIPTION_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchInscription: async ({ commit }, id) => {
        const response = await httpClient.get(`${INSCRIPTION_URL}/${id}`)
            .catch(error => error)
        commit('setInscription', response.data)

    },
    createInscription: async ({ commit }, data) => {
        await httpClient.post(INSCRIPTION_URL, data).then((response) => {
            commit('setInscription', response.data)
            window.console.log(commit)
            window.console.log(response.data)
        })
            .catch(error => error)



    },

}

export default actions;