const mutations = {
    setOrganisations: (state, payload) => {
        state.organisations = payload;
    },
    setOrganisation: (state, payload) => {
        state.organisation = payload;
    },
    setActeurs: (state, payload) => {
        state.acteurs = payload;
    }
};
export default mutations;