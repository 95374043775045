
const getters = {
    SESSIONS:state => state.sessions,
    SESSION: state => state.session,
    INSCRIPTIONS:state => state.Inscriptions,
    INSCRIPTION:state => state.Inscription,
    STATUS:state => state.status


}
export default getters;