const mutations = {
    setEntity: (state, payload) => {
        state.entities = payload;
    },
    setEntitie: (state, payload) => {
        state.entitie = payload;
    }
    ,
    setOrganisation: (state, payload) => {
        state.organisation = payload;
    }
    ,
    setActeurs: (state, payload) => {
        state.acteur = payload;
    }
};
export default mutations;