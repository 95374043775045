<template>
  <nav aria-label="breadcrumb ">
    <ol class="breadcrumb m-0 p-1 px-3">
      <li
        class="breadcrumb-item"
        v-for="(route, index) in routeList"
        :key="index"
      >
        <span
          class="text-primary"
          v-if="
            routeList[index - 1] &&
            routeList[index - 1].meta &&
            routeList[index - 1].meta.navigationName
          "
          >{{ route.meta.navigationName }}</span
        >
        <span
          style="text-decoration: none !important"
          :to="route.path"
          v-else
          >{{ route.meta.navigationName }}</span
        >
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  computed: {
    pathArr() {
      return this.$route.fullPath.split("/");
    },
    routeList() {
      return this.$route.matched;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {},
};
</script>

<style></style>
