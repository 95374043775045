const mutations = {
    setApplications: (state, payload) => {
        state.applications = payload;
    },
    setApplication: (state, payload) => {
        state.application = payload;
    },
    setApplicationBeneficaires: (state, payload) => {
        state.beneficaires = payload;
    },
    setApplicationCapacites: (state, payload) => {
        state.capacites = payload;
    },
    setApplicationComposants: (state, payload) => {
        state.composants = payload;
    },
    setApplicationFonctions: (state, payload) => {
        state.fonctions = payload;
    },
    setApplicationProcessus: (state, payload) => {
        state.processus = payload;
    },
    setApplicationTransformations: (state, payload) => {
        state.transformations = payload;
    },
    setApplicationApi : (state, payload) => {
        state.api = payload;
    },
    setApplicationReferents: (state, payload) => {
        state.referents = payload;
    },
    setApplicationTraitements : (state, payload) => {
        state.traitements = payload;
    },
    setApplicationObjets : (state, payload) => {
        state.objects = payload;
    },
    setApplicationCadres : (state, payload) => {
        state.cadres = payload;
    },
    setApplicationFluxs : (state, payload) => {
        state.fluxs = payload;
    }

};
export default mutations;