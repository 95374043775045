import { httpClient } from "../../../../helpers/services/HttpClient";
import { SESSION_URL } from "@/helpers/constURL";
import { concat } from "lodash"

const actions = {
    fetchAllSessions: async ({ commit }) => {
        const response = await httpClient.get(SESSION_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${SESSION_URL
                }?page=${i}`).then((response) => {
                    allData = concat(allData, response.data['hydra:member'])
                })

        }
        window.console.log(allData)
        commit('setSessions', allData);


    },
    fetchStatut_session: async ({ commit }) => {
        const response = await httpClient.get('/configuration/status_sessions').catch(err => window.console.log(err));
        const data = response.data['hydra:member'];
        commit('setStatuts', data);

    }
    , fetchSession: async ({ commit }, id) => {
        const response = await httpClient.get(`gouvernance/sessions/${id}`)
            .catch(error => error)
        commit('setSession', response.data);
    },
    createDossierFile: async ({ commit }, data) => {
        const response = await httpClient.post(`gouvernance/sessions/${data.id}/file`, data.file)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        return response.data;

    },
    fetchAllInscriptions: async ({ commit }) => {
        const response = await httpClient.get('gouvernance/inscriptions').catch(err => window.console.log(err));
        const data = response.data['hydra:member'];
        commit('setInscriptions', data);
    },
    fetchInscription: async ({ commit }, id) => {
        const response = await httpClient.get(`gouvernance/inscriptions/${id}`)
            .catch(error => error)
        commit('setInscription', response.data);
    },
    deleteinscription: async ({ commit }, id) => {
        const response = await httpClient.delete(`gouvernance/inscriptions/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deletesession: async ({ commit }, id) => {
        const response = await httpClient.delete(`gouvernance/sessions/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    createSession: async ({ commit }, data) => {
        const response = await httpClient.post('gouvernance/sessions', data)
            .catch(error => error)
        window.console.log(commit)
        commit('setSession', response.data);
        window.console.log(response)
    },
    createInscription: async ({ commit }, data) => {
        const response = await httpClient.post('gouvernance/inscriptions', data)
            .catch(error => error)
        commit("setInscription", response.data)
        window.console.log(commit)
        window.console.log(response)
    },
    updateSession: async ({ commit }, data) => {
        window.console.log(data, 'updateSession')
        const response = await httpClient.put(`gouvernance/sessions/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
        commit('setSession', response.data)
    },
    updateInscription: async ({ commit }, data) => {
        const response = await httpClient.put(`gouvernance/inscriptions/${data.id}`, data)
            .catch(error => error)
        commit('setInscriptions', response.data);

        window.console.log(commit)
        window.console.log(response)


    },

}

export default actions;