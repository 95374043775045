
function routesByRoles(routes, getters) {

    var isManager = getters.isManager;
    var isCollaborator = getters.isCollaborator
    var isArchitect = getters.isArchitect
    var isPo = getters.isPo
    var isGouvernance = getters.isGouvernance
    var isFinance = getters.isFinance
    var isAdmin = getters.isAdmin
    var isSuper = getters.isSuper
    let routesByRoles = [];

    // we gonna start with filtering the global routes for each role : 
    routesByRoles = routes.filter((route) => route.name !== "login" && route.name !== "account") // remove login route
        .filter((route) => route.name !== "404") // remove errors route
        .filter(route => {

            if (route.name === 'fdr') {
                if (isGouvernance) {
                    return false
                }
                else return true
            }

            else if (route.name === 'visions') {
                if (isGouvernance) { return false }
                if (isCollaborator) { return false }
                return true
            }

            else if (route.name === "organisations") {
                if (isFinance) { return false }
                return true
            }

            else if (route.name === "committees") {
                if (isCollaborator) { return false }
                else if (isFinance) { return false }
                return true
            }
            else if (route.name === "settings" || route.name === "Sécurité") {
                if (isAdmin || isSuper)
                    return true
                else return false
            }
            else return true

        })

        // then we gonna filter the routes cildren for each role :
        .map(route => {

            if (route.name === 'fdr') {
                if (isManager) {
                    return { ...route, children: route.children.filter((child, index) => index !== 1) }
                }
                else if (isCollaborator) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else if (isFinance) {
                    return { ...route, children: route.children.filter((child, index) => index === 0) }

                }
                else return route
            }

            else if (route.name === 'visions') {

                if (isFinance) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else if (isPo) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else if (isArchitect) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else return route
            }

            else if (route.name === "organisations") {
                if (isGouvernance) {
                    return { ...route, children: route.children.filter((child, index) => index === 0) }
                }
                else return route
            }

            else if (route.name === "committees") {
                if (isArchitect) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else if (isPo) {
                    return { ...route, children: route.children.filter((child, index) => index !== 0) }
                }
                else return route
            }
            else return route

        });
    return routesByRoles
}

export default routesByRoles;