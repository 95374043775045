<template>
  <section class="login-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="content-wrapper d-flex align-items-stretch auth auth-img-bg"
        >
          <div class="row flex-grow">
            <div
              class="col-lg-6 d-flex align-items-center justify-content-center"
            >
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>

                <form class="pt-3" @submit.prevent="login">
                  <div class="form-group">
                    <label for="username">Email</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span
                          class="input-group-text bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-account-outline text-primary"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        v-model="username"
                        class="form-control form-control-lg border-left-0"
                        id="username"
                        placeholder="example@example.com"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword">Mot de passe</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span
                          class="input-group-text bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        v-model="password"
                        class="form-control form-control-lg border-left-0"
                        id="exampleInputPassword"
                        placeholder="Mot de passe"
                      />
                    </div>
                  </div>
                  <div
                    class="
                      my-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input" /><i
                          class="input-helper"
                        ></i>
                        Se souvenir de moi
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-info"
                      >Mot passe oublié?</a
                    >
                  </div>
                  <div class="my-3">
                    <button
                      type="submit"
                      class="
                        btn btn-block btn-gradient-primary btn-lg
                        font-weight-medium
                        auth-form-btn
                      "
                      :disabled="authStatus === 'loading'"
                    >      <b-spinner
                        small
                        v-if="authStatus === 'loading'"
                      ></b-spinner>
                      Connexion
                
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <button
                      type="button"
                      class="btn btn-facebook auth-form-btn flex-grow mr-1"
                    >
                      <i class="mdi mdi-facebook mr-2"></i>Facebook
                    </button>
                    <button
                      type="button"
                      class="btn btn-google auth-form-btn flex-grow ml-1"
                    >
                      <i class="mdi mdi-google mr-2"></i>Google
                    </button>
                  </div>
                  <div class="text-center mt-4 font-weight-light">
                    Vous n'avez pas de compte?
                    <a href="#" class="text-primary">S'inscrire</a>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 login-half-bg d-flex flex-row">
              <p
                class="
                  text-white
                  font-weight-medium
                  text-center
                  flex-grow
                  align-self-end
                "
              >
                Copyright © 2021
                <a href="https://callstrategy.fr/" target="_blank"
                  >CallStrategy</a
                >. Tous les droits sont réservés..
              </p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "login-2",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
      error: null,
    };
  },

  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),

    authStatus: function () {
      return this.$store.getters.authStatus;
    },
  },
  beforeMount() {
    if (this.isLoggedIn) this.$router.push("/");
  },
  mounted() {
    if (this.isLoggedIn) this.$router.push("/");
  },
  methods: {
    login: function () {
      let username = this.username;
      let password = this.password;
      this.$store
        .dispatch("login", { username, password })
        .then(() => this.$router.push("/"))
        .catch((err) => {
          // let response = err.response;
          window.console.log(err);
          // if (response.status === 401) {
          //   this.error = response.data.message;
          // }
        });
    },
  },
};
</script>
