import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { CADRE_URL } from "@/helpers/constURL"
const actions = {
    fetchAllCadres: async ({ commit }) => {
        const response = await httpClient.get(CADRE_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${CADRE_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setCadres', allData);
    },
    createCadre: async ({ commit }, data) => {
        await httpClient.post(CADRE_URL, data).then((response) => {
            commit('setCadre', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteCadre: async ({ commit }, id) => {
        const response = await httpClient.delete(`${CADRE_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchCadre: async ({ commit }, id) => {
        await httpClient.get(`${CADRE_URL}/${id}`).then(
            (response) =>
                commit('setCadre', response.data)

        )
            .catch(error => error)

    },
    fetchComposant: async ({ commit }, id) => {
        await httpClient.get(`${CADRE_URL}/${id}/composants`).then(
            (response) =>
                commit('setComposant', response.data)
        ).catch(error => error)

    },
    fetchApplication: async ({ commit }, id) => {
        await httpClient.get(`${CADRE_URL}/${id}/applications`).then(
            (response) =>
                commit('setApplication', response.data)
        ).catch(error => error)

    },
    updateCadre: async ({ commit }, data) => {
        await httpClient.put(`${CADRE_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;