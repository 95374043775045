import { httpClient } from "@/helpers/services/HttpClient";
import {concat} from "lodash";

const actions = {
    fetchAllOrganisations: async ({ commit }) => {
        const response = await httpClient.get('referentiel/organisations')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/organisations?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setOrganisations', allData);
    }
    ,
    fetchOrganisation: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/organisations/${id}`)
            .catch(error => error)
        window.console.log(response.data,"Single organisation HAHAHAHAHA")
        commit('setOrganisation', response.data)

    },
     createOrganisation: async ({ commit }, data) => {
         const response = await httpClient.post('referentiel/organisations', data)
             .catch(error => error)
         window.console.log(commit)
         // window.console.clear()
         window.console.log(response)
         commit('setOrganisation', response.data)
         return response.data;
     },
    updateOrganisation: async ({ commit }, data) => {
        window.console.log(data,'updatesOrganisation')
        const response = await httpClient.put(`referentiel/organisations/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteOrganisation: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/organisations/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    fetchActeur: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/organisations/${id}/teams`)
            .catch(error => error)
        window.console.log(response.data,"Single organisation HAHAHAHAHA")
        commit('setActeurs', response.data)

    },
};

export default actions;