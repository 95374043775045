import { httpClient } from "@/helpers/services/HttpClient";
import { API_URL } from '@/helpers/constURL'

import { concat } from "lodash";

const actions = {
    fetchAllApis: async ({ commit }) => {
        const response = await httpClient.get(API_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${API_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setApis', allData);
    }
    ,
    fetchApi: async ({ commit }, id) => {
        const response = await httpClient.get(`${API_URL}/${id}`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setApi', response.data)

    },
    createApi: async ({ commit }, data) => {
        const response = await httpClient.post(API_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        commit('setApi', response.data)
        return response.data;
    },
    updateApi: async ({ commit }, data) => {
        const response = await httpClient.put(`${API_URL}/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteApi: async ({ commit }, id) => {
        const response = await httpClient.delete(`${API_URL}/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },

};

export default actions;