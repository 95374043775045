const mutations = {
    setFinanceFiles: (state, payload) => {
        state.financeFiles = payload;
    }
    , setFinanceFile: (state, payload) => {
        state.financeFile = payload;
    },
   
    
};
export default mutations;