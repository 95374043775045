import layout from "@/layout";

const companyReference = {
  path: "/reference",
  component: layout,
  redirect: "/reference/organisations",
  name: "organisations",

  meta: {
    title: "Référentiel d'entreprise",
    navigationName: "Référentiel d'entreprise",
    iconClass: "mdi-notebook",
    requiresAuth: true,

  },
  children: [
    {
      path: "organisations",
      name: "organisations",

      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Organisation",
        navigationName: "Organisation",
        iconClass: "mdi mdi-domain",

      },
      children: [
        {
          path: "",
          name: "organisations",
          component: () =>
            import("@/pages/company-reference/organisation/Index"),
          meta: {
            title: "Organisations",
            navigationName: "",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "entity/create",
          name: "add-entity",
          component: () =>
            import("@/pages/company-reference/organisation/entity/Add"),
          meta: {
            title: "Organisations",
            navigationName: "Création d'entité",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "entity/edit",
          name: "edit-entity",
          component: () =>
            import("@/pages/company-reference/organisation/entity/Edit"),
          meta: {
            title: "Organisations",
            navigationName: "Modification d'entité",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "entity/show/:slug-:id",
          name: "show-entity",
          component: () =>
            import("@/pages/company-reference/organisation/entity/Show"),
          meta: {
            title: "Organisations",
            navigationName: "Consultation d'entité",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "organisation/create",
          name: "add-organisation",
          component: () =>
            import("@/pages/company-reference/organisation/organisation/Add"),
          meta: {
            title: "Organisations",
            navigationName: "Création",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "organisation/edit",
          name: "edit-organisation",
          component: () =>
            import("@/pages/company-reference/organisation/organisation/Edit"),
          meta: {
            title: "Organisations",
            navigationName: "Modification",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "organisation/show/:slug-:id",
          name: "show-organisation",
          component: () =>
            import("@/pages/company-reference/organisation/organisation/Show"),
          meta: {
            title: "Organisations",
            navigationName: "Consultation",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "team/show/:slug-:id",
          name: "show-team",
          component: () =>
            import("@/pages/company-reference/organisation/team/Show"),
          meta: {
            title: "Equipe",
            navigationName: "Consultation",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "team/create",
          name: "add-team",
          component: () =>
            import("@/pages/company-reference/organisation/team/Add"),
          meta: {
            title: "Equipe",
            navigationName: "Création",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "team/edit",
          name: "edit-team",
          component: () =>
            import("@/pages/company-reference/organisation/team/Edit"),
          meta: {
            title: "Modification d'équipe",
            navigationName: "Modification d'équipe",
            iconClass: "mdi mdi-domain",
          },
        },
        {
          path: "add-actor",
          name: "add-actor",
          component: () => import("@/pages/company-reference/organisation/Add"),
          meta: {
            title: "Acteurs",
            navigationName: "Création",
            iconClass: "mdi mdi-account-group",
          },
        },
        {
          path: "edit-actor",
          name: "edit-actor",
          component: () => import("@/pages/company-reference/organisation/Edit"),
          meta: {
            title: "Acteurs",
            navigationName: "Modification",
            iconClass: "mdi mdi-account-group",
          },
        },
        {
          path: "show-actor/:slug-:id",
          name: "show-actor",
          component: () => import("@/pages/company-reference/organisation/Show"),
          meta: {
            title: "Acteurs",
            navigationName: "Consultation",
            iconClass: "mdi mdi-account-group",
          },
        },
        {
          path: "add-payee",
          name: "add-payee",
          component: () => import("@/pages/company-reference/organisation/payee/Add"),
          meta: {
            title: "Acteurs",
            navigationName: "Bénéficiaire",
            iconClass: "mdi mdi-account-group",
          },
        },
        {
          path: "edit-payee",
          name: "edit-payee",
          component: () => import("@/pages/company-reference/organisation/payee/Edit"),
          meta: {
            title: "Acteurs",
            navigationName: "Bénéficiaire",
            iconClass: "mdi mdi-account-group",
          },
        },
        {
          path: "show-payee/:slug-:id",
          name: "show-payee",
          component: () => import("@/pages/company-reference/organisation/payee/Show"),
          meta: {
            title: "Acteurs",
            navigationName: "Bénéficiaire",
            iconClass: "mdi mdi-account-group",
          },
        },
      ],
    },
    {
      path: "vue-metier",
      name: 'business-capacity',
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Vue métier",
        navigationName: "Vue métier",
        iconClass: "mdi mdi-arrow-down-bold-outline ",
      },

      children: [
        {
          path: "",
          name: "business-capacity",
          component: () =>
            import("@/pages/company-reference/business-capacity/Index"),
          meta: {
            title: "Vue métier",
            navigationName: "",
            iconClass: "mdi mdi-arrow-right-bold-outline ",
          },
        },

        {
          path: "processus/add",
          name: "add-job-process",
          component: () => import("@/pages/company-reference/business-capacity/job-process/Add"),
          meta: {
            title: "Processus Métier",
            navigationName: "Création",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "processus/edit",
          name: "edit-job-process",
          component: () => import("@/pages/company-reference/business-capacity/job-process/Edit"),
          meta: {
            title: "Processus Métier",
            navigationName: "Modification",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "processus/show/:slug-:id",
          name: "show-job-process",
          component: () => import("@/pages/company-reference/business-capacity/job-process/Show"),
          meta: {
            title: "Consultation",
            navigationName: "Consultation",
            iconClass: "mdi mdi-animation",
          },
        },
      ],
    },

    {
      path: "data",
      name: "data",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Vue données",
        navigationName: "Vue données",
        iconClass: "mdi mdi-database",
      },
      children: [
        {
          path: "",
          name: "data",
          component: () => import("@/pages/company-reference/data/Index"),
          meta: {
            title: "Vue données",
            navigationName: "",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "concept/create",
          name: "add-concept",
          component: () => import("@/pages/company-reference/data/concept/Add"),
          meta: {
            title: "Vue données",
            navigationName: "Création de concept",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "concept/edit",
          name: "edit-concept",
          component: () =>
            import("@/pages/company-reference/data/concept/Edit"),
          meta: {
            title: "Vue données",
            navigationName: "Modification de concept",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "concept/show/:slug-:id",
          name: "show-concept",
          component: () =>
            import("@/pages/company-reference/data/concept/Show"),
          meta: {
            title: "Vue données",
            navigationName: "Consultation d'objet métier",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "business-object/create",
          name: "add-business-object",
          component: () =>
            import("@/pages/company-reference/data/business-object/Add"),
          meta: {
            title: "Vue données",
            navigationName: "Création d'objet métier",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "business-object/edit",
          name: "edit-business-object",
          component: () =>
            import("@/pages/company-reference/data/business-object/Edit"),
          meta: {
            title: "Vue données",
            navigationName: "Modification d'objet métier",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "business-object/show/:slug-:id ",
          name: "show-business-object",
          component: () =>
            import("@/pages/company-reference/data/business-object/Show"),
          meta: {
            title: "Vue données",
            navigationName: "Consultation d'objet métier",
            iconClass: "mdi mdi-database",
          },
        },
      ],
    },

    {
      path: "vue-applicative",
      name: "application",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Vue applicative",
        navigationName: "Vue applicative",
        iconClass: "mdi mdi-cellphone-key",

      },
      children: [
        {
          path: "",
          name: "application",
          component: () =>
            import("@/pages/company-reference/application/Index"),
          meta: {
            title: "Vue applicative",
            navigationName: "",
            iconClass: "mdi mdi-cellphone-key",

          },
        },
        {
          path: "create",
          name: "add-application",
          component: () => import("@/pages/company-reference/application/Add"),
          meta: {
            title: "Vue applicative",
            navigationName: "Création",
            iconClass: "mdi mdi-cellphone-key",

          },
        },
        {
          path: "edit",
          name: "edit-application",
          component: () => import("@/pages/company-reference/application/Edit"),
          meta: {
            title: "Vue applicative",
            navigationName: "Modification",
            iconClass: "mdi mdi-cellphone-key",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-application",
          component: () => import("@/pages/company-reference/application/Show"),
          meta: {
            title: "Vue applicative",
            navigationName: "Consultation",
            iconClass: "mdi mdi-cellphone-key",

          },
        },
        {
          path: "print/:slug-:id",
          name: "print-application",
          component: () =>
            import("@/pages/company-reference/application/Print"),
          meta: {
            title: "Vue applicative",
            navigationName: "Impression",
            iconClass: "mdi mdi-printer",
          },
        },
        {
          path: "infosys-capacity",
          name: "infosys-capacity",
          component: () =>
            import("@/pages/company-reference/application/infoSys-capacity/Index"),
          meta: {
            title: "Capacité SI",
            navigationName: "",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "infosys-capacity/create",
          name: "add-infosys-capacity",
          component: () =>
            import("@/pages/company-reference/application/infoSys-capacity/Add"),
          meta: {
            title: "Capacité SI",
            navigationName: "Création",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "infosys-capacity/edit",
          name: "edit-infosys-capacity",
          component: () =>
            import("@/pages/company-reference/application/infoSys-capacity/Edit"),
          meta: {
            title: "Capacité SI",
            navigationName: "Modification",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "infosys-capacity/show/:slug-:id",
          name: "show-infosys-capacity",
          component: () =>
            import("@/pages/company-reference/application/infoSys-capacity/Show"),
          meta: {
            title: "Capacité SI",
            navigationName: "Consultation",
            iconClass: "mdi mdi-database",
          },
        },

      ],
    },

    {
      path: "vue-technique",
      name: 'technical-capacity',
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Vue technique",
        navigationName: "Vue technique",
        iconClass: "mdi mdi-database",
      },

      children: [

        {
          path: "",
          name: "technical-capacity",
          component: () =>
            import("@/pages/company-reference/technical-capacity/Index"),
          meta: {
            title: "Vue technique",
            navigationName: "",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "create",
          name: "add-technical-capacity",
          component: () =>
            import("@/pages/company-reference/technical-capacity/Add"),
          meta: {
            title: "Capacité Technique",
            navigationName: "Création",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "edit",
          name: "edit-technical-capacity",
          component: () =>
            import("@/pages/company-reference/technical-capacity/Edit"),
          meta: {
            title: "Capacité Technique",
            navigationName: "Modification",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-technical-capacity",
          component: () =>
            import("@/pages/company-reference/technical-capacity/Show"),
          meta: {
            title: "Capacité Technique",
            navigationName: "Consultation",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "composant",
          name: "composant",
          component: () => import("@/pages/company-reference/technical-capacity/Index"),
          meta: {
            title: "Composant technique",
            navigationName: "",
            // iconClass: "mdi mdi-animation",
            iconClass: "mdi mdi-sitemap",
          },
        },
        {
          path: "composant/create",
          name: "add-composant",
          component: () => import("@/pages/company-reference/technical-capacity/composants/Add"),
          meta: {
            title: "Composant technique",
            navigationName: "Création",
            // iconClass: "mdi mdi-animation",
            iconClass: "mdi mdi-sitemap",
          },
        },
        {
          path: "composant/edit",
          name: "edit-composant",
          component: () => import("@/pages/company-reference/technical-capacity/composants/Edit"),
          meta: {
            title: "Composant technique",
            navigationName: "Modification",
            // iconClass: "mdi mdi-animation",
            iconClass: "mdi mdi-sitemap",
          },
        },
        {
          path: "composant/show/:slug-:id",
          name: "show-composant",
          component: () => import("@/pages/company-reference/technical-capacity/composants/Show"),
          meta: {
            title: "Composant technique",
            navigationName: "Consultation",
            iconClass: "mdi mdi-sitemap",
          },
        },


      ],
    },

    {
      path: "principes",
      name: "principes",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Cadres d’entreprise",
        navigationName: "Cadres d’entreprise",
        iconClass: "mdi mdi-database",
      },
      children: [
        {
          path: "",
          name: "principes",
          component: () => import("@/pages/company-reference/principes/Index"),
          meta: {
            title: "Cadres d’entreprise",
            navigationName: "",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "create",
          name: "add-principe",
          component: () => import("@/pages/company-reference/principes/Add"),
          meta: {
            title: "Principes",
            navigationName: "Création",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "edit",
          name: "edit-principe",
          component: () => import("@/pages/company-reference/principes/Edit"),
          meta: {
            title: "Principes",
            navigationName: "Modification",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-principe",
          component: () => import("@/pages/company-reference/principes/Show"),
          meta: {
            title: "Principes",
            navigationName: "Consultation",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "refs-card/create",
          name: "add-principe-ref",
          component: () => import("@/pages/company-reference/principes/refcard/Add"),
          meta: {
            title: "Principes",
            navigationName: "Création cadre",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "refs-card/edit",
          name: "edit-principe-ref",
          component: () => import("@/pages/company-reference/principes/refcard/Edit"),
          meta: {
            title: "Principes",
            navigationName: "Modification cadre",
            iconClass: "mdi mdi-animation",
          },
        },
        {
          path: "refs-card/show/:slug-:id",
          name: "show-principe-ref",
          component: () => import("@/pages/company-reference/principes/refcard/Show"),
          meta: {
            title: "Principes",
            navigationName: "Consultation cadre",
            iconClass: "mdi mdi-animation",
          },
        },
      ],
    },
  ],
};

export default companyReference;
