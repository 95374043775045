import { httpClient } from "../../../../helpers/services/HttpClient";
import { DOSSIER_URL } from '@/helpers/constURL'

const actions = {
    fetchAllDossier: async ({ commit }) => {
        const res = await httpClient.get(DOSSIER_URL).catch(err => window.console.log(err));
        const data = res.data['hydra:member'];
        window.console.log(data, "Dossier");
        commit('setDOSSIER', data);

    },
    updateDossier: async ({ commit }, data) => {
        const response = await httpClient.put(`${DOSSIER_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setdossiers', response.data);

        window.console.log(commit)
        window.console.log(response)
    },
    deleteDossier: async ({ commit }, id) => {
        window.console.log(id)
        commit;
        await httpClient.delete(`${DOSSIER_URL}/${id}`)
            .catch(error => error)

    },
    createDossier: async ({ commit }, data) => {
        const response = await httpClient.post(DOSSIER_URL, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .catch(error => error)
        window.console.log(commit)
        commit('setdossiers', response.data)
        window.console.log(response)
        return response.data;

    },
    createDossierFile: async ({ commit }, data) => {
        const response = await httpClient.post(`${DOSSIER_URL}/${data.id}/file`, data.file)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        return response.data;

    },

}

export default actions;