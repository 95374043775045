<template>
  <section style="position: relative; z-index: 1000" class="app-sidebar bg-white">
    <nav class="sidebar sidebar-offcanvas bg-white" id="sidebar">
      <ul class="nav bg-white">
        <div class="nv bg-white" style="min-height: calc(100vh - 70px) !important">
          <li v-for="(route, index) in routes" :key="index + 'list_itm'" class="nav-item">
            <hr v-if="route.meta.title === 'Administration'" />
            <span
              v-if="route.hasOwnProperty('children')"
              :class="{
                'nav-link': true,
                'matched-link': matchedLink(route),
              }"
              v-b-toggle="route.name"
            >
              <span class="menu-title border-0 text-truncate">{{ route.meta.title }}</span>
              <i class="menu-arrow"></i>
              <i
                :class="[
                  `mdi menu-icon ${route.meta.iconClass}`,
                  matchedLink(route) ? 'text-primary' : 'text-secondary',
                ]"
              ></i>
            </span>

            <router-link
              v-if="!route.hasOwnProperty('children')"
              class="nav-link"
              :to="route.path"
              @click.native="hideMobileSidebar()"
            >
              <span class="menu-title text-truncate">{{ route.meta.title }}</span>
              <i
                :class="[
                  `mdi menu-icon ${route.meta.iconClass}`,
                  route.name === $route.name ? 'text-primary' : 'text-secondary',
                ]"
              ></i>
            </router-link>

            <b-collapse
              v-if="route.hasOwnProperty('children')"
              accordion="sidebar-accordion"
              :id="route.name"
            >
              <ul class="nav flex-column sub-menu nav-itemo">
                <li
                  v-for="(childRoute, ind) in route.children"
                  :key="ind + ' li na uk'"
                  class="nav-item"
                >
                  <router-link
                    :class="{
                      'nav-link': true,
                      active: matchedLink(childRoute),
                    }"
                    :to="
                      route.path != '/'
                        ? route.path + '/' + childRoute.path
                        : route.path + childRoute.path
                    "
                    @click.native="hideMobileSidebar()"
                    >{{ childRoute.meta.title }}</router-link
                  >
                </li>
              </ul>
            </b-collapse>
          </li>
        </div>
      </ul>
    </nav>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import routesByRoles from "@/helpers/routesByRoles";
export default {
  name: "sidebar",
  computed: {
    routes() {
      // return this.$router.options.routes.filter((route) => route.name !== "login");
      return routesByRoles(this.$router.options.routes, this.$store.getters);
    },
    ...mapGetters([
      "isManager",
      "isAdmin",
      "isSuper",
      "isPo",
      "isArchitect",
      "isFinance",
      "isCollaborator",
    ]),
  },
  methods: {
    hideMobileSidebar: () => {
      document.querySelector("#sidebar").classList.remove("active");
    },
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    setSelectedTabs() {
      let rt = this.$route;
      document.querySelectorAll(".sidebar .nav-item").forEach((item) => {
        if (item && item.children && item.children.length > 0) {
          let isSelected = false;

          for (
            let i = 0;
            i < item.children[1]
              ? item.children[1].children[0].children[0].children.length
              : 0;
            i++
          ) {
            const element = item.children[1].children[0].children[0].children[i];

            let sbArr = element.children[0]
              ? element.children[0].href.split("/").filter((_, ind) => ind > 2)
              : [];

            let tmproute = "/";

            sbArr.forEach((it, index) => {
              tmproute += it == "" ? "" : index < sbArr.length - 1 ? `${it}/` : it;
            });

            if (rt == tmproute) {
              isSelected = true;
            }
          }

          if (isSelected) {
            item.parentElement.parentElement.children[0].style.color = "#fbc41e";
          } else {
            item.parentElement.parentElement.children[0].style.color = "#888888";
          }
        }
      });
    },
    matchedLink(route) {
      let routeNames = [route.name];
      if (route.hasOwnProperty("children")) {
        routeNames = [...routeNames, ...route.children.map((r) => r.name)];

        for (const childRoute of route.children) {
          if (childRoute.hasOwnProperty("children")) {
            routeNames = [...routeNames, ...childRoute.children.map((r) => r.name)];
            if (childRoute.children.hasOwnProperty("children")) {
              routeNames = [
                ...routeNames,
                ...childRoute.children.children.map((r) => r.name),
              ];
              if (childRoute.children.children.hasOwnProperty("children")) {
                routeNames = [
                  ...routeNames,
                  ...childRoute.children.children.children.map((r) => r.name),
                ];
              }
            }
          }
        }
      }

      // window.console.log(route, "route");
      // window.console.log(routeNames, "routeNames");
      // window.console.log(this.$route.name, "this.$route.name");

      return routeNames.includes(this.$route.name);
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      ////
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          // el.classList.add("hover-open");
        }
        // body.classList.remove("sidebar-icon-only"); //
      });
      el.addEventListener("mouseout", function () {
        // body.classList.add("sidebar-icon-only");

        if (body.classList.contains("sidebar-icon-only")) {
          // el.classList.remove("hover-open");
        }
      });
    });

    document.querySelector(".app-sidebar").addEventListener("mouseover", function () {
      if (window.sessionStorage.sidebarOpen === "false") {
        body.classList.remove("sidebar-icon-only"); //
        document.querySelector(".app-sidebar").style.position = "absolute";
        // document.querySelector("#logo").style.position = "absolute";
      }
    });
    document.querySelector(".app-sidebar").addEventListener("mouseout", function () {
      if (window.sessionStorage.sidebarOpen === "false") {
        body.classList.add("sidebar-icon-only");
        document.querySelector(".app-sidebar").style.position = "absolute";
        // document.querySelector("#logo").style.position = "absolute";
      }
    });

    this.setSelectedTabs();
  },
  watch: {
    $route() {
      this.setSelectedTabs();
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>

<style scoped>
.nav {
  position: relative;
  width: inherit;
}

.nv {
  position: fixed;
  width: inherit;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
}

.sidebar-icon-only .nv {
  overflow-y: unset !important;
  max-height: unset !important;
}

.current {
  color: blue;
}

.matched-link {
  background-color: #072648;
  color: #ffffff !important;
  /* margin: 0.5rem 0 !important; */
  padding: 0.8rem 0.8rem !important;
  border-radius: 8px;
}

.collapsed,
.nav-link {
  margin: 0.5rem 0 !important;
  padding: 0.8rem 0.8rem !important;
  border-radius: 10px;
}

.sidebar-icon-only .collapsed,
.sidebar-icon-only .nav-link {
  margin: 0 !important;
}

/* .sidebar .nav .nav-item span {
  border-radius: 10px;
} */

.sidebar .nav .nav-item {
  padding: 0 1.8rem;
}

.sidebar-icon-only .sidebar .nav .nav-item {
  padding: 0 !important;
  margin: 0 5px;
}

.sidebar-icon-only .menu-title {
  border: 1px solid #072648;
  border-bottom: none;
}

.sidebar-icon-only .nav-item .collapse {
  border: 1px solid #072648;
  border-top: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    left: 60px;
    width: 225px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse {
    left: 60px;
    width: 225px;
  }

  #sidebar > ul > div > li.nav-item.hover-open > span > span {
    color: #ffc000;
  }
}
</style>
