import { httpClient } from "../../../../helpers/services/HttpClient";
import { LIVRABLE_URL } from '@/helpers/constURL'
import {concat} from "lodash"
const actions = {
    fetchAllLivrables: async ({ commit }) => {
        const response = await httpClient.get(LIVRABLE_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${LIVRABLE_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setLivrables', allData);

    },
    createLivrable: async ({ commit }, data) => {
        const response = await httpClient.post(LIVRABLE_URL, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    deleteLivrable: async ({ commit }, id) => {
        const response = await httpClient.delete(`${LIVRABLE_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },

};

export default actions;