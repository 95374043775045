import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
// import vGanttChart from 'v-gantt-chart';
// import FrappeGantt from 'frappe-gantt';
// import FrappeGantt from 'vue-frappe-gantt';
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";

import store from '@/store/index'
import vueaxios from "vue-axios";
import {instance} from "./helpers/services/api";
Vue.use(vueaxios, instance);
Vue.use(store);

// Datepicker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'vue2-datepicker/locale/fr';
Vue.use(DatePicker);

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

// Moment js
import moment from "moment";
moment.locale('fr', {
  week : {
        dow : 1, // Monday is the first day of the week.
    }
});

Vue.prototype.moment = moment;
window.moment = moment;

const momentLocale = require("moment/locale/fr");
Vue.use(require("vue-moment"), {
  momentLocale,
});


Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.component("multiselect", Multiselect);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
