<template>
  <div id="app">
    <transition name="fade">
      <div>
        <div
          :class="{
            'loading-overlay': $store.state.loader.isLoadingScreen,
            'loading-overlay-hide': !$store.state.loader.isLoadingScreen,
          }"
        >
          <div class="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <router-view></router-view>
      </div>
    </transition>
  </div>
</template>

<script>
import SortedTablePlugin from "vue-sorted-table";
import Vuelidate from "vuelidate"; //validation
import Vue from "vue";
Vue.use(SortedTablePlugin);
Vue.use(Vuelidate); // validation

export default {
  name: "app",
  components: {},
  beforeMount() {
    if (this.$store.getters["isLoggedIn"]) this.$store.dispatch("refreshPayload");
  },
  updated: function () {
    let theads = document.querySelectorAll("thead");
    if (theads.length > 0) {
      theads.forEach((it) => {
        if (
          it.parentElement.parentElement.className.split(" ")[0] != "mx-calendar-content"
        ) {
          it.style.background = "#e7e7e7";
        }
        // if()
      });
    }
  },
};
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";

@media (min-width: 1600px) {
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.loading-overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  opacity: 1;
  transition: all 0.3s;
}

.loading-overlay-hide {
  display: none;
  opacity: 0;
  transition: all 0.3s;
}

.jumping-dots-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
