import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { VERSION_URL } from "@/helpers/constURL"
const actions = {


    fetchAllVersions: async ({ commit }, page = VERSION_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${VERSION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setVersions', allData);
    },
    createVersion: async ({ commit }, data) => {
        await httpClient.post(VERSION_URL, data).then((response) => {
            commit('setVersion', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteVersion: async ({ commit }, id) => {
        const response = await httpClient.delete(`${VERSION_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchVersion: async ({ commit }, id) => {
        await httpClient.get(`${VERSION_URL}/${id}`).then(
            (response) =>
                commit('setVersion', response.data)

        )
            .catch(error => error)

    },
    fetchApplication: async ({ commit }, id) => {
        await httpClient.get(`referentiel/composants/${id}/applications`).then(
            (response) =>
                commit('setApplication', response.data)

        )
            .catch(error => error)

    },
    updateVersion: async ({ commit }, data) => {
        await httpClient.put(`${VERSION_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;