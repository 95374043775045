import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash"
import { DECISION_URL } from '@/helpers/constURL'

const actions = {
    fetchDecisions: async ({ commit }) => {
        const response = await httpClient.get(DECISION_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${DECISION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        window.console.log(allData)
        commit('setDecisions', allData);
    },
    updateDecision: async ({ commit }, data) => {
        window.console.log(data, 'updaetmembers')
        const response = await httpClient.put(`${DECISION_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setDecision', response.data)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteInscription: async ({ commit }, id) => {
        const response = await httpClient.delete(`${DECISION_URL}/${id}`)
            .catch(error => error)
        commit('setDecision', response.data)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    createDecision: async ({ commit }, data) => {
        const response = await httpClient.post(DECISION_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        commit('setDecision', response.data)
        return response.data;

    },

}

export default actions;