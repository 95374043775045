//TRANSFORMATION MODULE
export const INITIATIVE_URL = 'transformation/initiatives'
export const FDR_URL = 'transformation/feuille_routes'
export const Finance_Initiative_URL = 'transformation/finance_initiatives'
export const GAIN_URL = 'transformation/gains'
export const IMPACT_URL = 'transformation/impacts'
export const IMPUTATION_URL = 'transformation/imputations'
export const LIVRABLE_URL = 'transformation/livrables'
export const PATTERN_URL = 'transformation/patterns'
export const PHASE_URL = 'transformation/phases'
export const TASK_URL = 'transformation/tasks'
export const FILE_FINANCE_URL = 'transformation/file_finances'
export const TRANSFORMATION_PLAN_URL = 'transformation/transformation_plan'
export const SEMAINE_URL = 'transformation/semaines'
export const API_URL = 'referentiel/api_exposes'
export const Fluxe_URL = 'referentiel/fluxes'
export const PATRIMOINE_URL = 'referentiel/patrimoines'

//REFERENTIEL MODULE
export const ATTRUBUT_URL = 'referentiel/attributs'
export const BUSINESS_LINE_URL = 'referentiel/business_lines'
export const EDITEUR_URL = 'referentiel/editeurs'
export const VERSION_URL = 'referentiel/versions'
export const PRINCIPE_URL = 'referentiel/principes'
export const CADRE_URL = 'referentiel/cadre_entreprises'
export const APPLICATION_URL = "referentiel/applications"
export const FONCTION_URL = "referentiel/fonctions"
export const REF_PHASE_URL = "referentiel/ref_phases"
export const KPI_URL = "referentiel/kpis"
export const REFERENT_URL = "referentiel/referents"
export const APPLICATION_FILE_URL = "referentiel/file_applications"
export const COMPOSANT_FILE_URL = "referentiel/file_composants"
export const COMPOSANT_URL = "referentiel/composants"
export const ORGANISATION_URL = "referentiel/organisations"
export const PROCESSUS_METIER_URL = "referentiel/processus_metiers"
export const CAPACITE_METIER_URL = "referentiel/capacite_metiers"
export const OBJET_URL = "referentiel/objets"
export const ENTITE_URL = "referentiel/entites"
export const CONTRIBUTION_URL = "referentiel/contributions"
export const TRAITEMENT_URL = "referentiel/traitements"
export const CAPACITE_TECHNIQUE = "referentiel/capacite_techniques"
//  STRATEGY MODULE 
export const OBJECTIF_URL = 'strategy/objectifs'
export const COUVERTURE_URL = 'strategy/couvertures'
// GOUVERNANCE MODULE
export const COMITE_URL = 'gouvernance/comites'
export const MEMBER_URL = 'gouvernance/comites/members'
export const DECISION_URL = 'gouvernance/decisions'
export const DOSSIER_URL = 'gouvernance/dossiers'
export const INSCRIPTION_URL = 'gouvernance/inscriptions'
export const INVITE_URL = 'gouvernance/invites'
export const FILE_CADRS_URL = 'referentiel/file_cadres'
export const SESSION_URL = 'gouvernance/sessions'
// FINANCIEL MODULE
export const AJUSTEMENT_URL = 'financial/ajustements'
export const CONSOMATION_URL = 'financial/consommations'
export const FINANCE_FEUILLE_URL = 'transformation/finance_feuilles'
export const FINANCE_INITIATIVE_URL = 'transformation/finance_initiatives'

// CONFIGURATION MODULE 
export const PORTE_URL = 'configuration/portes'
export const CURRENCY_URL = 'currencies' //'configuration/currencies'
export const STATUS_URL = 'configuration/statuses'
export const ROLE_URL = 'configuration/roles'
export const TYPE_URL = 'configuration/types'

export const REFERENT_ROLE_URL = 'configuration/role_referents'
export const PORTE_APPLICATION_URL = 'configuration/porte_applications'
export const PORTE_COMPOSANT_URL = 'configuration/porte_composants'

export const STATUS_APPLICATION_URL = 'configuration/status_applications'
export const STATUS_COMITE_URL = 'configuration/status_comites'
export const STATUS_COMPOSANT_URL = 'configuration/status_composants'
export const STATUS_CONTRIBUTEUR_URL = 'configuration/status_contributeurs'
export const STATUS_FEUILLE_URL = 'configuration/status_feuilles'
export const STATUS_INITIATIVE_URL = 'configuration/status_initiatives'
export const STATUS_INSCRIPTION_URL = 'configuration/status_inscriptions'
export const STATUS_PHASE_URL = 'configuration/status_phases'
export const STATUS_REF_CARD_URL = 'configuration/status_ref_cards'
export const STATUS_SEMAINE_URL = 'configuration/status_semaines'
export const STATUS_SESSION_URL = 'configuration/status_sessions'
export const STATUS_TASK_URL = 'configuration/status_tasks'
export const STATUS_VISION_URL = 'configuration/status_visions'

export const TYPE_ACTEUR_URL = 'configuration/type_acteurs'
export const TYPE_APPLICATION_URL = 'configuration/type_applications'
export const TYPE_BENEFICIAIRE_URL = 'configuration/type_beneficiaires'
export const TYPE_FEUILLE_URL = 'configuration/type_feuilles'
export const TYPE_GAIN_URL = 'configuration/type_gains'
export const TYPE_IMPACT_URL = 'configuration/type_impacts'
export const TYPE_INITIATIVE_URL = 'configuration/type_initiatives'
export const TYPE_KPI_URL = 'configuration/type_kpis'
export const TYPE_ORGANISATION_URL = 'configuration/type_organisations'
export const TYPE_REF_CARD_URL = 'configuration/type_ref_cards'
export const TYPE_TASK_URL = 'configuration/type_tasks'


// USER MODULE
export const USER_URL = 'users'

// DASHBOARD MODULE
export const STATS_URL = 'stats'