const mutations = {
    setInitiatives: (state, payload) => {
        state.initiatives = payload;
    },
    setView: (state, view) => {
        Object.assign(state, { view });
    },
    setTotalItems: (state, payload) => {
        state.totalItems = payload;
    },
    setInitiative: (state, payload) => {
        state.initiative = payload;
    },
    setInitiativeComite: (state, payload) => {
        state.comite = payload;
    },
    setInitiativeImputations: (state, payload) => {
        state.imputations = payload;
    },
    setInitiativeTasks: (state, payload) => {
        state.tasks = payload;
    },
    setInitiativeImpacts: (state, payload) => {
        state.impacts = payload;
    },
    setImputationsByDirections: (state, payload) => {
        state.imputationsByDirections = payload;
    }

};
export default mutations;