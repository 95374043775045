import { httpClient } from "./HttpClient";
import jwt_decode from 'jwt-decode';
import { alertService } from "@/helpers/services/alertService";

const INACTIVE_ACCOUNT_ERROR = 'Your user account is not active.'
const INVALIDE_CREDENTIALS_ERROR = 'Invalid credentials.'

let jwtToken = '';
let refreshToken = '';

export const useToken = () => jwtToken;

export const useRefreshToken = () => refreshToken;

export function updateToken(newToken) {
    jwtToken = newToken;
    localStorage.setItem('token', newToken);

}
export function updateRefreshToken(newRefreshToken) {
    refreshToken = newRefreshToken;
    localStorage.setItem('refreshToken', newRefreshToken);
}

class AuthService {
    async login(user) {
        const { data: { token, refresh_token } } = await httpClient.post('/login', {
            username: user.username,
            password: user.password
        }).catch(({ response: { data: { message } } }) => {
            let errorMessage = "" // default message
            switch (message) {
                case INACTIVE_ACCOUNT_ERROR:
                    errorMessage = "Votre compte utilisateur n'est pas actif." // user account is not active
                    break;
                case INVALIDE_CREDENTIALS_ERROR:
                    errorMessage = "Nom d'utilisateur ou mot de passe incorrect." // invalid credentials
                    break;
                default:
                    break;
            }
            alertService.notifyError(errorMessage)
        }
        );

        updateToken(token);
        updateRefreshToken(refresh_token);
        const payload = jwt_decode(token);

        return { token, refresh_token, payload };
    }

    logout() {
        localStorage.removeItem('user');
    }

    async refreshToken() {
        await httpClient.post('token/refresh', { 'refresh_token': useRefreshToken() })
            .then(resp => {
                updateToken(resp.data.token)
                updateRefreshToken(resp.data.refresh_token)
                window.console.log(resp.data)
                return resp
            })
            .catch((err) => {
                return err;
            })
    }

    decodePayload(token) {
        return jwt_decode(token);
    }
}

export default new AuthService();