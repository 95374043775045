import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllProcessusMetiers: async ({ commit }, page = 'referentiel/processus_metiers') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/processus_metiers?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setProcessusMetiers', allData);
    },
    createProcessusMetier: async ({ commit }, data) => {
        await httpClient.post('referentiel/processus_metiers', data).then((response) => {
            commit('setProcessusMetier', response.data)


        }).catch(error => error)
    },
    deleteProcessusMetier: async ({ commit }, id) => {
        await httpClient.delete(`referentiel/processus_metiers/${id}`)
            .catch(error => error)
        commit

    },
    fetchProcessusMetier: async ({ commit }, id) => {
        await httpClient.get(`referentiel/processus_metiers/${id}`).then(
            (response) =>
                commit('setProcessusMetier', response.data)

        )
            .catch(error => error)

    },
    fetchApplication: async ({ commit }, id) => {
        await httpClient.get(`referentiel/processus_metiers/${id}/applications`).then(
            (response) =>
                commit('setApplications', response.data)

        )
            .catch(error => error)

    },
    updateProcessusMetier: async ({ commit }, data) => {
        await httpClient.put(`referentiel/processus_metiers/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)

    },
    fetchAllProcessusMetierCapacites: async ({ commit }, id) => {
        await httpClient.get(`referentiel/processus_metiers/${id}/capacites`)
            .then((response) => {
                commit('setCapacites', response.data)


            }).catch(error => error)
        window.console.log(commit)

    },
    fetchAllProcessusMetierApplications: async ({ commit }, id) => {
        await httpClient.get(`referentiel/processus_metiers/${id}/applications`)
            .then((response) => {
                commit('setApplications', response.data)


            }).catch(error => error)
        window.console.log(commit)

    },

    fetchAllProcessusMetierObjets: async ({ commit }, id) => {
        await httpClient.get(`referentiel/processus_metiers/${id}/objets`)
            .then((response) => {
                commit('setObjets', response.data)


            }).catch(error => error)
        window.console.log(commit)

    }

};

export default actions;