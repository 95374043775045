const mutations = {
    setVisions: (state, payload) => {
        state.visions = payload;
    },
    setVisionSingle: (state, payload) => {
        state.vision = payload;
    },
  
    setVisionObjectifs: (state, payload) => {
        state.objectifs = payload
    }
} ;
export default mutations;