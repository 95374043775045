import { httpClient } from "@/helpers/services/HttpClient";
// import { concat } from "lodash";
import { AJUSTEMENT_URL } from "@/helpers/constURL";
const actions = {
    // fetchAllAjustements: async ({ commit }, page = AJUSTEMENT_URL) => {

    //     const response = await httpClient.get(page)
    //     const totalItems = response.data['hydra:totalItems'];
    //     var allData = []
    //     for (let i = 1; i <= (totalItems / 10) + 1; i++) {
    //         await httpClient.get(`ajustements?page=${i}`).then((response) => {
    //             allData = concat(allData, response.data['hydra:member'])
    //         })
    //     }
    //     commit('setAjustements', allData);
    // },
    createAjustement: async ({ commit }, data) => {
        await httpClient.post(AJUSTEMENT_URL, data).then((response) => {
            commit('setAjustement', response.data)

        }).catch(error => error)
    },
    deleteAjustement: async ({ commit }, id) => {
        await httpClient.delete(`${AJUSTEMENT_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchAjustement: async ({ commit }, id) => {
        await httpClient.get(`${AJUSTEMENT_URL}/${id}`).then(
            (response) =>
                commit('setAjustement', response.data)
        )
            .catch(error => error)

    },
    updateAjustement: async ({ commit }, data) => {
        await httpClient.put(`${AJUSTEMENT_URL}/${data.id}`, data)
            .then((response) => {

                commit('setAjustement', response.data)



            }).catch(error => error)
    }

};

export default actions;