const mutations = {
    setRoles: (state, payload) => {
        state.roles = payload;
    }
    , setRole: (state, payload) => {
        state.role = payload;
    }
    , setReferentRoles: (state, payload) => {
        state.referentRoles = payload;
    },
    setReferentRole: (state, payload) => {
        state.referentRole = payload;
    }

};
export default mutations;