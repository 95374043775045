const mutations = {
    setProcessusMetiers: (state, payload) => {
        state.processusMetiers = payload;
    }
    , setProcessusMetier: (state, payload) => {
        state.processusMetier = payload;
    },
    setApplications : (state, payload) => {
        state.applications = payload;
    },
    setCapacites: (state, payload) => {
        state.capacites = payload;
    },
    setObjets : (state, payload) => {
        state.objects = payload;
    }
};
export default mutations;