const mutations = {

    setComite:(state, payload) => {
        state.comite = payload;
    },
    setMembers:(state, payload) => {
        state.members = payload;
    },
    setInscriptions:(state, payload) => {
        state.inscription = payload;
    },
    setTotalItems: (state, payload) => {
        state.totalItems = payload;
    },
    setComites:(state, payload) =>{
        state.comites = payload;
    }
}
export default mutations;