import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { FDR_URL } from '@/helpers/constURL'
const actions = {
    fetchAllFdrs: async ({ commit }, page = FDR_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${FDR_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        } 
        commit('setFdrs', allData);
    },
    createFdr: async ({ commit }, data) => {
        await httpClient.post(FDR_URL, data).then((response) => {
            commit('setFdr', response.data)
            window.console.log(response)
        }).catch(error => error)
        window.console.log(commit)
    },
    deleteFdr: async ({ commit }, id) => {
        const response = await httpClient.delete(`${FDR_URL}/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchFdr: async ({ commit }, id) => {
        await httpClient.get(`${FDR_URL}/${id}`).then(
            (response) =>
                commit('setFdr', response.data)
        )
            .catch(error => error)

    },
    updateFdr: async ({ commit }, data) => {
        await httpClient.put(`${FDR_URL}/${data.id}`, data)
            .then((response) => {
                // commit('setFdr', response.data)
                window.console.log(response)
            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    },
    fetchFdrComites: async ({ commit }, id) => {
        await httpClient.get(`${FDR_URL}/${id}/comite`).then((response) => {
            commit('setFdrComite', response.data);
        })
    },
    fetchFdrInitiatives: async ({ commit }, id) => {
        await httpClient.get(`${FDR_URL}/${id}/initiatives`).then((response) => {
            commit('setFdrInitiatives', response.data);
        })
    },

    fetchFdrPlans: async ({ commit }, id) => {
        await httpClient.get(`${FDR_URL}/${id}/transformation`).then((response) => {
            commit('setFdrPlans', response.data);
        })
    },
    fetchFdrCouvertures: async ({ commit }, id) => {
        await httpClient.get(`${FDR_URL}/${id}/couvertures`).then((response) => {
            commit('setFdrCouvertures', response.data);
        })
    },

};

export default actions;