const mutations = {
    setCapacites: (state, payload) => {
        state.capacites = payload;
    }
    , setCapacite: (state, payload) => {
        state.capacite = payload;
    },
    setCapaciteTechniqueCadres: (state, payload) => {
        state.cadres = payload;
    }

};
export default mutations;