const getters = {
    PATRIMOINES: state => state.patrimoines,
    APPLICATION: state => state.application,
    PATRIMOINE: state => state.patrimoine,
    REFERENTS: state => state.referents,
    PROCESSUS: state => state.processus,
    FDRS: state => state.fdr,
    TRANSFORMATION: state => state.transformation,
    COMPOSANT: state => state.composant,
    CADRES: state => state.cadres,

}

export default getters;