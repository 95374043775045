import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import {
    STATUS_URL,
    STATUS_APPLICATION_URL,
    STATUS_COMITE_URL,
    STATUS_COMPOSANT_URL,
    STATUS_CONTRIBUTEUR_URL,
    STATUS_FEUILLE_URL,
    STATUS_INITIATIVE_URL,
    STATUS_INSCRIPTION_URL,
    STATUS_PHASE_URL,
    STATUS_REF_CARD_URL,
    STATUS_SEMAINE_URL,
    STATUS_TASK_URL,
    STATUS_VISION_URL
} from "@/helpers/constURL";
import { getUrlByType, getAllStatusByType } from "@/helpers/storeHelpers";
const actions = {


    createStatusByType: async ({ commit }, { type, data }) => {
        let url = getUrlByType(type);
        await httpClient.post(url, data)
            .then(response => {
                window.console.log(response);
                commit
            })
            .catch(error => {
                window.console.log(error);
            });

    },
    updateStatusByType: async ({ commit }, { type, data }) => {
        let url = `${getUrlByType(type)}/${data.id}`;
        await httpClient.put(url, data)
            .then(response => {
                window.console.log(response);
                commit
            })
            .catch(error => {
                window.console.log(error);
            });
    },

    fetchAllStatuses: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatuses', allData);
    },

    deleteStatus: async ({ commit }, id) => {
        await httpClient.delete(`${STATUS_URL}/${id}`)
            .catch(error => error)
        commit
    },

    fetchAllStatusByType: async ({ commit }, type) => {
        let url = getUrlByType(type);
        await getAllStatusByType(url, commit, `setStatus${type}s`);
    },

    fetchAllStatusApplications: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_APPLICATION_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusApplications', allData);
    },
    fetchAllStatusComites: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_COMITE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusComites', allData);
    },
    fetchAllStatusComposants: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_COMPOSANT_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusComposants', allData);
    },
    fetchAllStatusContributeurs: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_CONTRIBUTEUR_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusContributeurs', allData);
    },
    fetchAllStatusFeuilles: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_FEUILLE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusFeuilles', allData);
    },
    fetchAllStatusInitiatives: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_INITIATIVE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusInitiatives', allData);
    },
    fetchAllStatusInscriptions: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_INSCRIPTION_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusInscriptions', allData);
    },
    fetchAllStatusPhases: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_PHASE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusPhases', allData);
    },
    fetchAllStatusRefCards: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_REF_CARD_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusRefCards', allData);
    },
    fetchAllStatusSemaines: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_SEMAINE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusSemaines', allData);
    },
    fetchAllStatusTasks: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_TASK_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusTasks', allData);
    },
    fetchAllStatusVisions: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${STATUS_VISION_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setStatusVisions', allData);
    }


};

export default actions;