import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllCapacites: async ({ commit }, page = 'referentiel/capacite_sis') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/capacite_sis?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setCapacites', allData);
    },
    createCapacite: async ({ commit }, data) => {
       await httpClient.post('referentiel/capacite_sis', data).then((response) => {
            commit('setCapacite', response.data)
           window.console.log(response)

            
        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteCapacite: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/capacite_sis/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchCapacite: async ({ commit }, id) => {
        await httpClient.get(`referentiel/capacite_sis/${id}`).then(
            (response) => 
                commit('setCapacite', response.data)

        )
            .catch(error => error)

    },
    updateCapacite: async ({ commit }, data) => {
     await httpClient.put(`referentiel/capacite_sis/${data.id}`, data)
        .then((response) => {
             window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
 
    }

};

export default actions;