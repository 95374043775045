const state = {
    patrimoines: [],
    application : [],
    patrimoine : [],
    referents : [],
    processus : [],
    fdr : [],
    composant : [],
    transformation : [],
    cadres : [],
}

export default state;