import layout from "@/layout";

const settings = {
    path: "/settings",
    component: layout,
    redirect: "/settings/configuration",
    name: "settings",
    meta: {
        title: "Administration",
        navigationName: "Administration",
        iconClass: "mdi-settings",
        requiresAuth: true,

    },
    children: [

        {
            path: "configuration",
            name: "Configuration",
            component: () => import("@/pages/settings/configuration/Index"),
            meta: {
                title: "Configuration",
                navigationName: "Configuration",
                iconClass: "mdi mdi-monitor",
            },
        },
        {
            path: "users",
            name: "users et permissions",
            component: () => import("@/pages/settings/users/Index"),

            meta: {
                title: "Utilisateurs et Permissions",
                navigationName: "Utilisateurs et permissions",
                iconClass: "mdi mdi-format-list-checks ",
            },
        },
        // {
        //     path: "permissions",
        //     name: "permissions",
        //     component: () => import("@/pages/settings/permissions/Index"),
        //     meta: {
        //         title: "Permissions",
        //         navigationName: "Permissions",
        //         iconClass: "mdi mdi-earth",
        //     },
        // },
    ]
};

export default settings;
