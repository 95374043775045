const mutations = {
    setCadres: (state, payload) => {
        state.cadreEnreprises = payload;
    },
    setCadre: (state, payload) => {
        state.cadreEnreprise = payload;
    },
    setComposant : (state, payload) => {
        state.composant = payload;
    },
    setApplication : (state, payload) => {
        state.application = payload;
    },

};
export default mutations;