import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { OBJECTIF_URL } from "@/helpers/constURL"
const actions = {
    fetchAllObjectifs: async ({ commit }) => {
        const response = await httpClient.get(OBJECTIF_URL)
        // const data = filterRequestData(response);
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${OBJECTIF_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setObjectif', allData);
    }
    ,
    fetchObjectif: async ({ commit }, id) => {
        const response = await httpClient.get(`${OBJECTIF_URL}/${id}`)
            .catch(error => error)
        commit('setObjectifSingle', response.data)

    },
    createObjectif: async ({ commit }, data) => {
        const response = await httpClient.post(OBJECTIF_URL, data)
            .catch(error => error)
        window.console.log(commit)
        commit('setObjectifSingle', response.data)
        window.console.log(response)
        return response.data;
    },
    deleteObjectif: async ({ commit }, id) => {
        const response = await httpClient.delete(`${OBJECTIF_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    }, updateObjectif: async ({ commit }, data) => {
        await httpClient.put(`${OBJECTIF_URL}/${data.id}`, data).then((response) => {
            commit('setObjectifSingle', response.data)
        }).catch(error => error)

        window.console.log(commit)
        window.console.clear()
    },
    fetchFdrsObjectif: async ({ commit }, id) => {
        const response = await httpClient.get(`${OBJECTIF_URL}/${id}/feuilles_routes`)
            .catch(error => error)
        commit('setFdrs', response.data)

    },


};

export default actions;