import { httpClient } from "@/helpers/services/HttpClient";
// import { concat } from "lodash";
import { CONSOMATION_URL } from "@/helpers/constURL";
const actions = {
    
    createConsommation: async ({ commit }, data) => {
        await httpClient.post(CONSOMATION_URL, data).then((response) => {
            commit('setConsommation', response.data)

        }).catch(error => error)
    },
    deleteConsommation: async ({ commit }, id) => {
        await httpClient.delete(`${CONSOMATION_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchConsommation: async ({ commit }, id) => {
        await httpClient.get(`${CONSOMATION_URL}/${id}`).then(
            (response) =>
                commit('setConsommation', response.data)
        )
            .catch(error => error)

    },
    updateConsommation: async ({ commit }, data) => {
        await httpClient.put(`${CONSOMATION_URL}/${data.id}`, data)
            .then((response) => {

                commit('setConsommation', response.data)



            }).catch(error => error)
    }

};

export default actions;