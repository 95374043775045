import Vue from "vue";
import Router from "vue-router";

import auth from "./routes/auth";
import companyStrategy from "./routes/companyStrategy";
import home from "./routes/home";
import transformationStrategy from "./routes/transformationStrategy";
import companyReference from "./routes/companyReference";
import analytics from "./routes/analytics";
import governance from "./routes/governance";
import settings from "./routes/settings";
import errors from "./routes/errors";
import profile from "./routes/profile";
import store from "@/store/index";
import handleRoutesRedirect from "@/helpers/handleRoutesRedirect";

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  routes: [
    auth,
    home,
    companyStrategy,
    transformationStrategy,
    governance,
    companyReference,
    analytics,
    settings,
    errors,
    profile
  ],
});

router.beforeEach((to, from, next) => {
  if (from.name)
    store.dispatch("routerHistory/changePreviousRoute", from.name);


  if (to.name) {
    if (store.getters.isLoggedIn && to.name === "login") {
      next('/');
      return
    }
    else if (to.name !== "404" && to.name !== "401" && to.name !== "403" && to.name !== "500") // routes for errors
      next();
    else if (to.matched.some(record => record.meta.requiresAuth)) {
      //auth
      if (store.getters.isLoggedIn) {
        next();
        return
      }
      else
        next('/login')
    } else {
      next();
      return
    }
    let fromPath = from.path.split("/")[2];
    let toPath = to.path.split("/")[2];

    if (fromPath === toPath || fromPath === undefined) {
      const activeTab = JSON.parse(window.sessionStorage.getItem("tabIndex"));
      store.dispatch("tabs/changeTab", activeTab);
    } else {
      store.dispatch("tabs/changeTab", 0);
    }

    next();
  }
  else {
    next('404')
    return;
  }
});

router.beforeEach((to, from, next) => {
  handleRoutesRedirect(to, router, store.getters);
  store.dispatch('loader/toggleLoading', true);
  next();
})

router.afterEach((to) => {
  if (
    to.name.includes("add") ||
    to.name.includes("show")
  ) {
    true;
  }
  if (to.name.includes("dashboard")) {
    setTimeout(() => {
      store.dispatch('loader/toggleLoading', false);
    }, 3000);
  }
  else if (!to.name.includes("show"))
    setTimeout(() => {
      store.dispatch('loader/toggleLoading', false);
    }, 500);
});

export default router;
