import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { FONCTION_URL } from "@/helpers/constURL"
const actions = {


    fetchAllFonctions: async ({ commit }, page = FONCTION_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${FONCTION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setFonctions', allData);
    },
    createFonction: async ({ commit }, data) => {
        await httpClient.post(FONCTION_URL, data).then((response) => {
            commit('setFonction', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteFonction: async ({ commit }, id) => {
        const response = await httpClient.delete(`${FONCTION_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchFonction: async ({ commit }, id) => {
        await httpClient.get(`${FONCTION_URL}/${id}`).then(
            (response) =>
                commit('setFonction', response.data)

        )
            .catch(error => error)

    },
    updateFonction: async ({ commit }, data) => {
        await httpClient.put(`${FONCTION_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;