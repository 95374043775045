import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { FILE_FINANCE_URL } from '@/helpers/constURL'
const actions = {
    fetchAllFinanceFiles: async ({ commit }, page = FILE_FINANCE_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${FILE_FINANCE_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setFinanceFiles', allData);
    },
    createFinanceFile: async ({ commit }, data) => {
        await httpClient.post(FILE_FINANCE_URL, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            commit('setFinanceFile', response.data)
            window.console.log(response)


        }).catch(error => error)
    },
    deleteFinanceFile: async ({commit},id) => {
        window.console.log(commit)
        await httpClient.delete(`${FILE_FINANCE_URL}/${id}`)
            .catch(error => error)



    },
    fetchFinanceFile: async ({ commit }, id) => {
        await httpClient.get(`${FILE_FINANCE_URL}/${id}`).then(
            (response) =>
                commit('setFinanceFile', response.data)

        )
            .catch(error => error)

    },
    updateFinanceFile: async (data) => {
        await httpClient.put(`${FILE_FINANCE_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
    },


};

export default actions;