import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllTeams: async ({ commit }) => {
        const response = await httpClient.get('referentiel/teams')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/teams?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setTeam', allData);
    },
    deleteTeam: async ({ commit }, id) => {
        await httpClient.delete(`referentiel/teams/${id}`)
            .catch(error => error)
        window.console.clear()
        commit
    },
    fetchTeam: async ({ commit }, id) => {
        await httpClient.get(`referentiel/teams/${id}`)
            .then((response) => {
                commit('setTeams', response.data)
            })
            .catch(error => error)

    },
    fetchMembers: async ({ commit }, id) => {
        await httpClient.get(`referentiel/teams/${id}/members`)
            .then((response) => {
                commit('setMembers', response.data)
            })
            .catch(error => error)

    },
    createTeam: async ({ commit }, data) => {
        await httpClient.post('referentiel/teams', data)
            .then((response) => {
                commit('setTeams', response.data)
            })
            .catch(error => error)

    },
    updateTeam: async ({ commit }, data) => {
        await httpClient.put(`referentiel/teams/${data.id}`, data)
            .then((response) => {
                commit('setTeams', response.data)
            })
            .catch(error => error)
    },
}

export default actions;