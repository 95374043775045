import { httpClient } from "../../../../helpers/services/HttpClient";
import { FILE_CADRS_URL } from '@/helpers/constURL'
const actions = {

    createCadrsFile: async ({ commit }, data) => {
        await httpClient.post(FILE_CADRS_URL, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            commit('setCadreFile', response.data)
            window.console.log(response)


        }).catch(error => error)
    },
    deleteCadrsFile: async ({ commit }, id) => {
        const response = await httpClient.delete(`${FILE_CADRS_URL}/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    fetchCadrsFile: async ({ commit }, id) => {
        await httpClient.get(`${FILE_CADRS_URL}/${id}`).then(
            (response) =>
                commit('setCadreFile', response.data)

        )
            .catch(error => error)

    },
    updateFinanceFile: async (data) => {
        await httpClient.put(`${FILE_CADRS_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
    },


};

export default actions;