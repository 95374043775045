const getters = {
    APPLICATIONS: state => state.applications,
    APPLICATION: state => state.application,
    BENEFICAIRES : state => state.beneficaires,
    CAPACITES: state => state.capacites,
    COMPOSANTS: state => state.composants,
    FONCTIONS: state => state.fonctions,
    PROCESSUS: state => state.processus,
    TRANSFORMATIONS: state => state.transformations,
    REFERENTS : state => state.referents,
    API : state => state.api,
    TRAITEMENTS : state => state.traitements,
    OBJECTS : state => state.objects,
    CADRES : state => state.cadres,
    FLUXS : state => state.fluxs,
}

export default getters;