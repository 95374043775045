const getters = {
    IMPUTATIONS: state => state.imputations,
    VIEW: state => state.view,
    TOTAL_ITEMS: state => state.totalItems,
    IMPUTATION: state => state.imputation,
    IMPUTATIONS_TASKS: state => state.tasks,
    TEAM_IMPUTATIONS: state => state.teamImputations,
    

}

export default getters;