const getters = {
   COMPOSANTS: state => state.composants,
   COMPOSANT: state => state.composant,
   TRANSFORMATION: state => state.transformation,
   VERSIONS: state => state.versions,
   APPLICATIONS: state => state.applications,
   COMPOSANT_CAPACITES: state => state.capacites,
   CADRES : state => state.cadres,
}

export default getters;