import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash"
import { SEMAINE_URL } from '@/helpers/constURL'

const actions = {
    fetchAllSemaines: async ({ commit }) => {
        const response = await httpClient.get(SEMAINE_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${SEMAINE_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        } 
        commit('setSemaines', allData);
    },
    updateSemaine: async ({ commit }, data) => {
         const response = await httpClient.put(`${SEMAINE_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setSemaine', response.data)
        window.console.log(commit)
       
    },
    deleteInscription: async ({ commit }, id) => {
        const response = await httpClient.delete(`${SEMAINE_URL}/${id}`)
            .catch(error => error)
        commit('setSemaine', response.data)
        window.console.log(commit)
        window.console.clear()
        


    },
    createSemaine: async ({ commit }, data) => {
        const response = await httpClient.post(SEMAINE_URL, data)
            .catch(error => error)
        window.console.log(commit)
   
        commit('setSemaine', response.data)
        return response.data;

    },

}

export default actions;