import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { PORTE_URL, PORTE_APPLICATION_URL, PORTE_COMPOSANT_URL } from "@/helpers/constURL";
const actions = {
    fetchAllPortes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${PORTE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setPortes', allData);
    },
    createPorte: async ({ commit }, data) => {
        await httpClient.post(PORTE_URL, data).then((response) => {
            commit('setPorte', response.data)

        }).catch(error => error)
    },
    deletePorte: async ({ commit }, id) => {
        await httpClient.delete(`${PORTE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchPorte: async ({ commit }, id) => {
        await httpClient.get(`${PORTE_URL}/${id}`).then(
            (response) =>
                commit('setPorte', response.data)
        )
            .catch(error => error)

    },
    updatePorte: async ({ commit }, data) => {
        await httpClient.put(`${PORTE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setPorte', response.data)



            }).catch(error => error)
    },
    createPortApplication: async ({ commit }, data) => {
        await httpClient.post(PORTE_APPLICATION_URL, data).then((response) => {
            commit('setPorteApplication', response.data)

        }).catch(error => error)
    },
    createPortComposant: async ({ commit }, data) => {
        await httpClient.post(PORTE_COMPOSANT_URL, data).then((response) => {
            commit('setPorteComposant', response.data)

        }).catch(error => error)
    },
    updatePortApplication: async ({ commit }, data) => {
        await httpClient.put(`${PORTE_APPLICATION_URL}/${data.id}`, data)
            .then((response) => {

                commit('setPorteApplication', response.data)
            }).catch(error => error)
    },
    updatePortComposant: async ({ commit }, data) => {
        await httpClient.put(`${PORTE_COMPOSANT_URL}/${data.id}`, data)
            .then((response) => {

                commit('setPorteComposant', response.data)
            }).catch(error => error)
    }
};

export default actions;