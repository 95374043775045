const mutations = {
    setImpacts: (state, payload) => {
        state.impacts = payload;
    },
    setView: (state, view) => {
        Object.assign(state, { view });
    },
    setTotalItems: (state, payload) => {
        state.totalItems = payload;
    },
    setImpact: (state, payload) => {
        state.impact = payload;
    },
    
};
export default mutations;