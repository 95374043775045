const state = {
    initiatives: [],
    view: [],
    totalItems: 0,
    initiative: {},
    comite: [],
    imputations: [],
    tasks: [],
    impacts: [],
    imputationsByDirections: []
}

export default state;