
const handleRoutesRedirect = async (currentRoute, router, getters) => {
    // if (currentRoute.path.includes('settings')) {
    //     if (!getters.isAdmin && !getters.isSuper) {
    //         router.push({ name: "404" });
    //     }
    // }
    // gouvernance role
    // else
    if (getters.isGouvernance) {
        if (
            //strategy
            currentRoute.path.includes("visions") ||
            currentRoute.path.includes("goals") ||
            // transformation
            currentRoute.path.includes("fdr") ||
            currentRoute.path.includes('initiative') ||
            currentRoute.path.includes('deliverable') ||
            currentRoute.path.includes('imputation') ||
            currentRoute.path.includes('activity') ||
            currentRoute.path.includes('patrimony') ||
            // refrentiel
            currentRoute.path.includes('business-capacity') ||
            currentRoute.path.includes('job-process') ||
            currentRoute.path.includes('concept') ||
            currentRoute.path.includes('business-object') ||
            currentRoute.path.includes('application') ||
            currentRoute.path.includes('infosys-capacity') ||
            currentRoute.path.includes('concept') ||
            currentRoute.path.includes('technical-capacity') ||
            currentRoute.path.includes('composant') ||
            currentRoute.path.includes('principe')
            // details 
            || currentRoute.name.includes('add-actor') //add collaborators
        ) {
            router.push({ name: "401" });
        }
        else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }
    //
    // Collaborator role
    else if (getters.isCollaborator) {
        if (
            //strategy
            currentRoute.path.includes("visions") ||
            currentRoute.path.includes("goals") ||
            // transformation
            currentRoute.path.includes("fdr") ||
            // gouvernance
            currentRoute.path.includes("committee")
            // details
            || currentRoute.name.includes('add-actor') //add collaborators
            || currentRoute.name.includes('add-team') //add team
            || currentRoute.name.includes('add-entity') //add entity
            || currentRoute.name.includes('add-organisation') //add organisation
            || currentRoute.name.includes('add-patrimony') //add patrimony
            || currentRoute.name.includes('add-process') //add process
            || currentRoute.name.includes('add-business-object') //add business object
            || currentRoute.name.includes('add-application') //add application
            || currentRoute.name.includes('add-composant') //add composant
            || currentRoute.name.includes('add-principe') //add principe
            || currentRoute.name.includes('add-principe-ref') //add principe ref
            || currentRoute.name.includes('add-technical-capacity') //add capacite technique
            || currentRoute.name.includes('show-technical-capacity') //edit capacite technique

        ) {
            router.push({ name: "401" });
        }
        else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }
    //

    // Finance role
    else if (getters.isFinance) {
        if (
            //strategy
            currentRoute.path.includes("visions") ||
            // transformation
            currentRoute.path.includes('initiative') ||
            currentRoute.path.includes('deliverable') ||
            currentRoute.path.includes('imputation') ||
            currentRoute.path.includes('activity') ||
            currentRoute.path.includes('patrimony') ||
            // gouvernance
            currentRoute.path.includes("committee")

        ) {
            router.push({ name: "401" });
        }
        else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }
    // 
    // PO role
    else if (getters.isPo) {
        if (
            //strategy
            currentRoute.path.includes("visions")
            // gouvernance
            // || currentRoute.path.includes("/committee")
            || currentRoute.path.includes("comites")
            || currentRoute.name.includes("comites")
            // details
            || currentRoute.name.includes('add-actor') //add collaborators
            || currentRoute.name.includes('add-team') //add team
            || currentRoute.name.includes('add-entity') //add entity
            || currentRoute.name.includes('add-organisation') //add organisation
            || currentRoute.name.includes('add-fdr') //add fdr
            || currentRoute.name.includes('add-patrimony') //add patrimony
            || currentRoute.name.includes('add-job-process') //add process Metier
            || currentRoute.name.includes('add-concept') //add concept Metier
            || currentRoute.name.includes('add-business-object') //add objet Metier
            || currentRoute.name.includes('add-technical-capacity') //add capacite technique
            || currentRoute.name.includes('show-technical-capacity') //edit capacite technique
        ) {
            router.push({ name: "401" });
        }
        else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }
    //
    // Architect role
    else if (getters.isArchitect) {
        if (
            //strategy
            currentRoute.path.includes("visions") ||
            // gouvernance
            currentRoute.path.includes("/committee")
            // details
            || currentRoute.name.includes('add-actor') //add collaborators
            || currentRoute.name.includes('add-team') //add team
            || currentRoute.name.includes('add-entity') //add entity
            || currentRoute.name.includes('add-organisation') //add organisation
            || currentRoute.name.includes('add-initiative') //add initiative
        ) {
            router.push({ name: "401" });
        } else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }

    //
    // Manager role
    else if (getters.isManager) {
        if (
            //transformation
            currentRoute.path.includes("initiative")
            || currentRoute.name.includes("initiative")
            // details
            || currentRoute.name.includes('add-initiative') //add initiative
            || currentRoute.name.includes('add-actor') //add collaborators
            || currentRoute.name.includes('add-team') //add team
            || currentRoute.name.includes('add-entity') //add entity
            || currentRoute.name.includes('add-organisation') //add organisation
            || currentRoute.name.includes('add-business-capacity') //add business capacity
            || currentRoute.name.includes('add-job-processy') //add job process
            || currentRoute.name.includes('add-concept') //add concept
            || currentRoute.name.includes('add-business-object') //add business object
            || currentRoute.name.includes('add-infosys-capacity') //add infosys capacity
            || currentRoute.name.includes('add-application') //add application
            || currentRoute.name.includes('add-technical-capacity') //add technical capacity
            || currentRoute.name.includes('add-composant') //add composant
            || currentRoute.name.includes('add-principe') //add principe
            || currentRoute.name.includes('add-principe-ref') //add principe ref
            || currentRoute.name.includes('add-committee') //add committee
            || currentRoute.name.includes('add-session') //add session
        ) {
            router.push({ name: "401" });
        }
        else if (currentRoute.path.includes('settings')) {
            router.push({ name: "404" });
        }
    }


}

export default handleRoutesRedirect;