const mutations = {
    setImputations: (state, payload) => {
        state.imputations = payload;
    },
    setView: (state, view) => {
        Object.assign(state, { view });
    },
    setTotalItems: (state, payload) => {
        state.totalItems = payload;
    },
    setImputation: (state, payload) => {
        state.imputation = payload;
    },
    setImputationsTasks: (state, payload) => {
        state.tasks = payload;
    },
    setTeamImputations: (state, payload) => {
        state.teamImputations = payload;
    }

};
export default mutations;