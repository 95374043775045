const mutations = {
    setCapaciteMetiers: (state, payload) => {
        state.capaciteMetiers = payload;
    }
    , setCapaciteMetier: (state, payload) => {
        state.capaciteMetier = payload;
    },
    setProcessus: (state, payload) => {
        state.processus = payload;
    }
    
};
export default mutations;