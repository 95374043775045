const state = {
    composants: [],
    composant: {},
    transformation: [],
    versions: [],
    applications: [],
    capacites: [],
    cadres: []
}

export default state;