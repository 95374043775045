import { httpClient } from "../../../../helpers/services/HttpClient";

const actions = {
    fetchAllCouvertures: async ({ commit }) => {
        const response = await httpClient.get('strategy/couvertures')
        const data = response.data['hydra:member'];
        window.console.log(data,"data");
        commit('setCouverture', data);
    },
    createCouverture: async ({ commit }, data) => {
        const response = await httpClient.post('strategy/couvertures', data)
            .catch(error => error)
        window.console.log(data)
        window.console.log(response)

        commit('setCouverture', response.data)

        return response.data;
    },
    deleteCouverture: async ({ commit }, id) => {
        const response = await httpClient.delete(`strategy/couvertures/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
 
    updateCouverture: async ({ commit }, data) => {
        const response = await httpClient.put(`strategy/couvertures/${data.id}`, data)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
        actions.fetchVision({ commit }, data.id)
        commit('setCouverture', response.data)
    },
    
};

export default actions;