const mutations = {
    setVersions: (state, payload) => {
        state.versions = payload;
    },
    setVersion: (state, payload) => {
        state.version = payload;
    },
    setApplication: (state, payload) => {
        state.applicaation = payload;
    },
};
export default mutations;