const mutations = {
    setRefPhases: (state, payload) => {
        state.refPhases = payload;
    },
    setRefPhase: (state, payload) => {
        state.refPhase = payload;
    },
 

};
export default mutations;