const loader = {
  namespaced: true,
  state: () => ({
    isLoadingScreen: true,
  }),
  mutations: {
    TOGGLE_LOADING(state, payload) {
      state.isLoadingScreen = payload;
    },
  },
  actions: {
    toggleLoading({ commit }, payload) {
      commit("TOGGLE_LOADING", payload);
    },
  },
  getters: {},
};

export default loader;
