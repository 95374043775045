const mutations={
    setPlannings:(state, payload) => {
        state.plannings = payload;
    },
    setInscription:(state, payload) => {
        state.inscription = payload;
    },
    setPlanning:(state, payload) => {
        state.planning = payload;
    },
}

export default mutations;