import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllCapacites: async ({ commit }, page = 'referentiel/capacites') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/capacites?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setCapacites', allData);
    },
    createCapacite: async ({ commit }, data) => {
       var response = await httpClient.post('referentiel/capacites', data).then((response) => {
            commit('setCapacite', response.data)
           window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.log(response)

    },
    deleteCapacite: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/capacites/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchCapacite: async ({ commit }, id) => {
        await httpClient.get(`referentiel/capacites/${id}`).then(
            (response) => 
                commit('setCapacite', response.data)

        )
            .catch(error => error)

    },
    updateCapacite: async ({ commit }, data) => {
     await httpClient.put(`referentiel/capacites/${data.id}`, data)
        .then((response) => {
            // commit('setCapacite', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
        // window.console.log(response)
        // actions.fetchTask({ commit }, data.id)
        // commit('setCapacite', response.data)
    }

};

export default actions;