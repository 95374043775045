import layout from "@/layout";

const governance = {
  path: "/governance",
  component: layout,
  redirect: "/governance/comite-inscription",
  name: "committees",
  meta: {
    title: "Gouvernance",
    navigationName: "Gouvernance",
    iconClass: "mdi-gavel",
    requiresAuth: true,

  },
  children: [
    {
      path: "comites",

      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Comités",
        navigationName: "Comités",
        iconClass: "mdi mdi-account-multiple",
      },
      children: [
        {
          path: "",
          name: "committees",
          component: () => import("@/pages/governance/committee/Index"),
          meta: {
            title: "Comités",
            navigationName: "",
            // iconClass: "mdi mdi-file-tree",
            iconClass: "mdi mdi-gavel",

          },
        },
        {
          path: "create",
          name: "add-committee",
          component: () => import("@/pages/governance/committee/Add"),
          meta: {
            title: "Comités",
            navigationName: "Création",
            // iconClass: "mdi mdi-file-tree",
            iconClass: "mdi mdi-gavel",

          },
        },
        {
          path: "edit",
          name: "edit-committee",
          component: () => import("@/pages/governance/committee/Edit"),
          meta: {
            title: "Comités",
            navigationName: "Modification",
            // iconClass: "mdi mdi-file-tree",
            iconClass: "mdi mdi-gavel",

          },
        },
        {
          path: "show/:slug-:id",
          name: "show-committee",
          component: () => import("@/pages/governance/committee/Show"),
          meta: {
            title: "Comités",
            navigationName: "Consultation",
            // iconClass: "mdi mdi-file-tree",
            iconClass: "mdi mdi-gavel",

          },
        },
        {
          path: "print/:slug-:id",
          name: "print-committee",
          component: () =>
            import("@/pages/governance/committee/Print"),
          meta: {
            title: "Comités",
            navigationName: "Consultation",
            iconClass: "mdi mdi-gavel",
          },
        },
      ],
    },
    {
      path: "comite-inscription",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Inscriptions & décisions",
        navigationName: "Inscriptions & décisions",
        iconClass: "mdi mdi-file",
      },
      children: [
        {
          path: "",
          name: "committee-inscription",
          component: () => import("@/pages/governance/manage-committee/Index"),
          meta: {
            title: "Inscriptions & décisions",
            navigationName: "",
            iconClass: "mdi mdi-file",
          },
        },
        {
          path: "session/create",
          name: "add-session",
          component: () => import("@/pages/governance/manage-committee/session/Add"),
          meta: {
            title: "Inscriptions & décisions",
            navigationName: "Création de session",
            iconClass: "mdi mdi-file",
          },
        },
        {
          path: "session/edit",
          name: "edit-session",
          component: () => import("@/pages/governance/manage-committee/session/Edit"),
          meta: {
            title: "Inscriptions & décisions",
            navigationName: "Modification de session",
            iconClass: "mdi mdi-file",
          },
        },
        {
          path: "session/show/:slug-:id",
          name: "show-session",
          component: () => import("@/pages/governance/manage-committee/session/Show"),
          meta: {
            title: "Inscriptions & décisions",
            navigationName: "Consultation de session",
            iconClass: "mdi mdi-file",
          },
        },

        {
          path: "inscription/create",
          name: "add-inscription",
          component: () => import("@/pages/governance/manage-committee/inscription/Add"),
          meta: {
            title: "S'inscrire aux comités",
            navigationName: "Création de inscription",
            iconClass: "mdi mdi-file",
          },
        },
        {
          path: "inscription/edit",
          name: "edit-inscription",
          component: () => import("@/pages/governance/manage-committee/inscription/Edit"),
          meta: {
            title: "S'inscrire aux comités",
            navigationName: "Modification de inscription",
            iconClass: "mdi mdi-file",
          },
        },
        {
          path: "inscription/show/:slug-:id",
          name: "show-inscription",
          component: () => import("@/pages/governance/manage-committee/inscription/Show"),
          meta: {
            title: "S'inscrire aux comités",
            navigationName: "Consultation de inscription",
            iconClass: "mdi mdi-file",
          },
        },
      ]
    },
  ],
};

export default governance;
