const getters = {
    STATUSES: state => state.statuses,
    STATUS: state => state.status,
    STATUS_TASKS: state => state.statusTasks,
    STATUS_APPLICATIONS: state => state.statusApplications,
    STATUS_COMITES: state => state.statusComites,
    STATUS_COMPOSANTS: state => state.statusComposants,
    STATUS_CONTRIBUTEURS: state => state.statusContributeurs,
    STATUS_FEUILLES: state => state.statusFeuilles,
    STATUS_INITIATIVES: state => state.statusInitiatives,
    STATUS_INSCRIPTIONS: state => state.statusInscriptions,
    STATUS_PHASES: state => state.statusPhases,
    STATUS_REF_CARDS: state => state.statusRefCards,
    STATUS_SEMAINES: state => state.statusSemaines,
    STATUS_SESSIONS: state => state.statusSessions,
    STATUS_VISIONS: state => state.statusVisions

}

export default getters;