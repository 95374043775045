import { httpClient } from "../../../helpers/services/HttpClient";

const state = {
    contributorRoles: [],
  

}
const getters = {
    CONTRIBUTOR_ROLES: state => state.contributorRoles,
 
}

const mutations = {
    setContributorRoles: (state, payload) => {
        state.contributorRoles = payload;
    },
   
}

const actions = {
    
    fetchAllContributorRoles: async ({ commit }) => {
        const response = await httpClient.get('/contributor/role').catch(error => window.console.log(error))
        const data = response.data; 
        commit('setContributorRoles', data)

    }
}

const RoleModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default RoleModule;