const state = {
    tasks: [],
    task: {},
    error: "",
    isLoading: false,
    selectItems: null,
    view: [],
    totalItems: 0,
    allTasks: [],
    teamTasks: []
}

export default state;