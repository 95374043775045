const state = {
    applications: [],
    application: {},
    beneficaires: [],
    capacites: [],
    composants: [],
    fonctions: [],
    processus: [],
    transformations: [],
    api: [],
    referents: [],
    traitements: [],
    objects: [],
    cadres: [],
    fluxs: []
}

export default state;