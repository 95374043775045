<template>
  <b-navbar
    id="template-header"
    class="
      navbar
      default-layout-navbar
      col-lg-12 col-12
      p-0
      fixed-top
      d-flex
      flex-row
      bg-secondary
      text-white
    "
    toggleable="lg"
  >
    <div
      class="
        text-center
        navbar-brand-wrapper
        d-flex
        align-items-top
        justify-content-center
      "
      id="logo"
    >
      <router-link class="navbar-brand brand-logo" to="/">
        <img class="w-100 h-100" src="@/assets/images/logo.png" alt="logo" />
      </router-link>
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/logo_min.png" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        class="
          navbar-toggler navbar-toggler
          align-self-center
          d-lg-block
          text-primary
        "
        type="button"
        @click="toggleSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
      <div class="search-field d-none d-md-block">
        <!-- <form action="#">
          <div class="d-flex align-items-center input-group">
            <div class="input-group-prepend bg-transparent">
              <i
                class="input-group-text border-0 mdi mdi-magnify text-primary"
              ></i>
            </div>
            <input
              type="text"
              class="form-control bg-transparent border-0"
              placeholder="Recherche"
            />
          </div>
        </form>  -->
      </div>

      <b-navbar-nav class="navbar-nav-right ml-auto">
        <!-- <b-nav-item-dropdown right class="preview-list">
          <template slot="button-content">
            <div class="nav-link count-indicator dropdown-toggle">
              <i class="mdi mdi-bell-outline text-primary"></i>
              <span class="count-symbol bg-danger"></span>
            </div>
          </template>
          <h6 class="p-3 mb-0">Informations</h6>
          <b-dropdown-item
            v-for="(item, index) in notification_data"
            :key="index + ' note'"
            class="preview-item"
          >
            <div class="preview-thumbnail">
              <div class="preview-icon bg-success">
                <i :class="`mdi ${item.icn}`"></i>
              </div>
            </div>
            <div
              class="
                preview-item-content
                d-flex
                align-items-start
                flex-column
                justify-content-center
              "
            >
              <h6 class="preview-subject font-weight-normal mb-1">
                {{ item.title }}
              </h6>
              <p class="text-gray ellipsis mb-0">
                {{ item.date }}
              </p>
            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown> -->
        <b-nav-item-dropdown role="button" right class="nav-profile">
          <template slot="button-content">
            <span
              @click="$router.push('/account')"
              class="nav-link dropdown-toggle"
              id="profileDropdown"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="nav-profile-img">
                <img
                  :src="
                    isLoggedIn &&
                    AuthenticatedUser &&
                    AuthenticatedUser.avatarUrl
                      ? IMG_URL + AuthenticatedUser.avatarUrl
                      : 'https://www.nicepng.com/png/full/136-1366211_group-of-10-guys-login-user-icon-png.png'
                  "
                  alt="image"
                  style="object-fit: cover"
                />
                <span class="availability-status online"></span>
              </div>

              <div class="nav-profile-text text-white">
                <p class="mb-1">
                  {{
                    isLoggedIn && AuthenticatedUser
                      ? `${AuthenticatedUser.firstname} ${AuthenticatedUser.lastname}`
                      : ""
                  }}
                </p>
              </div>
            </span>
          </template>
          <!-- <b-dropdown-item class="preview-item">
            <i class="mdi mdi-cached mr-2 text-success"></i> Journal d'activités
          </b-dropdown-item>
          <b-dropdown-item class="preview-item">
            <div class="d-flex" style="height: 43.19px">
              <i class="fa fa-gear my-auto mr-2"></i>
            </div>
            Configuration
          </b-dropdown-item> -->
        </b-nav-item-dropdown>
        <div class="nav-item button-content">
          <button
            class="
              nav-link
              count-indicator
              dropdown-toggle
              border-0
              bg-transparent
            "
            @click="logout"
          >
            <i class="mdi mdi-power text-primary"></i>
          </button>
        </div>
      </b-navbar-nav>
      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import { IMG_URL } from "@/helpers/services/api";
import handleRoutesRedirect from "@/helpers/handleRoutesRedirect";
import Swal from "sweetalert2";
export default {
  name: "app-header",
  methods: {
    toggleSidebar() {
      const bodyElement = document.querySelector("body");
      window.sessionStorage.setItem(
        "sidebarOpen",
        bodyElement.classList.contains("sidebar-icon-only")
      );
      bodyElement.classList.toggle("sidebar-icon-only");
      document.querySelector(".app-sidebar").style.position = "relative";
      this.$emit("sideBarChange", window.sessionStorage.sidebarOpen);
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    logout() {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Vous allez être déconnecté.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        confirmButtonColor: "#dd3333",
        cancelButtonText: "Non",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login");
          });
        }
      });
    },
  },
  data() {
    return {
      IMG_URL,
      notification_data: [
        {
          icn: "mdi-cellphone-link",
          title: "Application 1",
          date: "24/06/2021",
        },
        {
          icn: "mdi-target",
          title: "Décision 1",
          date: "24/06/2021",
        },
        {
          icn: "mdi-notebook-outline",
          title: "Pattern 1",
          date: "24/06/2021",
        },
        {
          icn: "mdi-lightbulb",
          title: "Inititive 1",
          date: "24/06/2021",
        },
      ],
    };
  },

  beforeEnter: (to, from, next) => {
    window.console.log(to, from);
    handleRoutesRedirect(to, this.$router, this.$store.getters);
    if (from.name)
      this.$store.dispatch("routerHistory/changePreviousRoute", from.name);
    next();
  },

  beforeCreate() {
    this.$store.dispatch("fetchUser").then(() => {});
    handleRoutesRedirect(this.$route, this.$router, this.$store.getters);
  },

  computed: {
    ...mapGetters(["AuthenticatedUser", "isLoggedIn"]),
  },
  beforeMount() {
    handleRoutesRedirect(this.$route, this.$router, this.$store.getters);

    if (!this.isLoggedIn) {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    }
  },
  mounted() {
    handleRoutesRedirect(this.$route, this.$router, this.$store.getters);
  },
};
</script>

<style scoped>
.brand-logo img,
.brand-logo-mini img {
  will-change: transform;
  image-rendering: -webkit-optimize-contrast;
}
</style>
