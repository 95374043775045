import layout from "@/layout";
const transformationStrategy = {
  path: "/transformation",
  name: "fdr",

  component: layout,
  redirect: "/transformation/fdr",

  meta: {
    title: "Stratégie de transformation",
    navigationName: "Stratégie de transformation",
    iconClass: "mdi-rocket",
    requiresAuth: true,


  },
  children: [
    {
      path: "fdr",
      redirect: "/transformation/fdr",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Feuilles de route",
        navigationName: "Fdr",
        iconClass: "mdi mdi-map",
      },
      children: [
        {
          path: "",
          name: "fdr",
          component: () => import("@/pages/transformation/fdr/Index"),
          meta: {
            title: "Feuilles de route",
            navigationName: "",
            iconClass: "mdi mdi-map",
          },
        },
        {
          path: "create",
          name: "add-fdr",
          component: () => import("@/pages/transformation/fdr/Create"),
          meta: {
            title: "Feuilles de route",
            navigationName: "Création",
            // iconClass: "mdi mdi-file-outline",
            iconClass: "mdi mdi-map",
          },
        },
        {
          path: "edit",
          name: "edit-fdr",
          component: () => import("@/pages/transformation/fdr/Edit"),
          meta: {
            title: "Feuilles de route",
            navigationName: "Modification",
            // iconClass: "mdi mdi-file-outline",
            iconClass: "mdi mdi-map",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-fdr",
          component: () => import("@/pages/transformation/fdr/Show"),
          meta: {
            title: "Feuilles de route",
            navigationName: "Consultation",
            // iconClass: "mdi mdi-file-outline",
            iconClass: "mdi mdi-map",
          },
        },
        {
          path: "print/:slug-:id",
          name: "print-fdr",
          component: () => import("@/pages/transformation/fdr/Print"),
          meta: {
            title: "Feuilles de route",
            navigationName: "Impression",
            // iconClass: "mdi mdi-file-outline",
            iconClass: "mdi mdi-map",
          },
        },
      ],
    },
    {
      path: "initiative",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Initiatives",
        navigationName: "Initiative",
        // iconClass: "mdi mdi-lightbulb-on-outline",
        iconClass: "mdi mdi-rocket",
      },
      children: [
        {
          path: "",
          name: "initiative",
          component: () => import("@/pages/transformation/initiative/Index"),
          meta: {
            title: "Initiatives",
            navigationName: "",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
        {
          path: "create",
          name: "add-initiative",
          component: () => import("@/pages/transformation/initiative/Create"),
          meta: {
            title: "Initiatives",
            navigationName: "Création",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
        {
          path: "edit",
          name: "edit-initiative",
          component: () => import("@/pages/transformation/initiative/Edit"),
          meta: {
            title: "Initiatives",
            navigationName: "Modification",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
        {
          path: "show/:slug-:id",
          name: "show-initiative",
          component: () => import("@/pages/transformation/initiative/Show"),
          meta: {
            title: "Initiatives",
            navigationName: "Consultation",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
      ],
    },
    {
      path: "deliverable",
      name: "deliverable",
      component: () => import("@/pages/transformation/deliverable/Index"),
      meta: {
        title: "Livrables",
        navigationName: "Livrables",
        iconClass: "mdi mdi-send",
      },
    },
    {
      path: "imputation",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Imputations",
        navigationName: "Imputations",
        iconClass: "mdi mdi-send",
      },
      children: [
        {
          path: "",
          name: "imputations",
          component: () => import("@/pages/transformation/imputation/Index"),
          meta: {
            title: "Imputations",
            navigationName: "",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
        {
          path: "activity/create",
          name: "add-activity",
          component: () =>
            import("@/pages/transformation/imputation/AddActivity"),
          meta: {
            title: "Imputations",
            navigationName: "Création",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
        {
          path: "activity/search",
          name: "search-activity",
          component: () =>
            import("@/pages/transformation/imputation/SearchActivity"),
          meta: {
            title: "Imputations",
            navigationName: "Recherche d'activité",
            // iconClass: "mdi mdi-lightbulb-on-outline",
            iconClass: "mdi mdi-rocket",

          },
        },
      ],
    },
    {
      path: "patrimony",
      title: "Patrimoine",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Patrimoines",
        navigationName: "Patrimoines",
        iconClass: "mdi mdi-database",
      },
      children: [
        {
          path: "",
          name: "patrimony",
          component: () => import("@/pages/transformation/patrimony/Index"),
          meta: {
            title: "Patrimoines",
            navigationName: "",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "create",
          name: "add-patrimony",
          component: () => import("@/pages/transformation/patrimony/Add"),
          meta: {
            title: "Patrimoine",
            navigationName: "Création",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "edit",
          name: "edit-patrimony",
          component: () => import("@/pages/transformation/patrimony/Edit"),
          meta: {
            title: "Patrimoine",
            navigationName: "Modification",
            iconClass: "mdi mdi-database",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-patrimony",
          component: () => import("@/pages/transformation/patrimony/Show"),
          meta: {
            title: "Patrimoine",
            navigationName: "Consultation",
            iconClass: "mdi mdi-database",
          },
        },
      ],
    },
  ],
};

export default transformationStrategy;
