const mutations = {
    setFluxes: (state, payload) => {
        state.fluxes = payload;
    },
    setFluxe: (state, payload) => {
        state.fluxe = payload;
    },
    setFluxObjects: (state, payload) => {
        state.objects = payload;
    }
};
export default mutations;