import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import {
    TYPE_URL
    , TYPE_ACTEUR_URL
    , TYPE_APPLICATION_URL
    , TYPE_BENEFICIAIRE_URL
    , TYPE_FEUILLE_URL
    , TYPE_GAIN_URL
    , TYPE_IMPACT_URL
    , TYPE_INITIATIVE_URL
    , TYPE_KPI_URL
    , TYPE_ORGANISATION_URL
    , TYPE_REF_CARD_URL
    , TYPE_TASK_URL
} from "@/helpers/constURL";
const actions = {

    fetchAllTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setTypes', allData);
    },

    createType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_URL, data).then((response) => {
            commit('setType', response.data)

        }).catch(error => error)
    },

    deleteType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_URL}/${id}`)
            .catch(error => error)
        commit
    },

    fetchType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_URL}/${id}`).then(
            (response) =>
                commit('setType', response.data)
        )
            .catch(error => error)

    },

    updateType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setType', response.data)



            }).catch(error => error)
    },

    fetchGainTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_GAIN_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setGainTypes', allData);
    },
    createGainType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_GAIN_URL, data).then((response) => {
            commit('setGainType', response.data)

        }).catch(error => error)
    },
    deleteGainType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_GAIN_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchGainType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_GAIN_URL}/${id}`).then(
            (response) =>
                commit('setGainType', response.data)
        )
            .catch(error => error)

    },
    updateGainType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_GAIN_URL}/${data.id}`, data)
            .then((response) => {

                commit('setGainType', response.data)
            }).catch(error => error)
    },

    fetchImpactTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_IMPACT_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setImpactTypes', allData);
    },
    createImpactType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_IMPACT_URL, data).then((response) => {
            commit('setImpactType', response.data)

        }).catch(error => error)
    },
    deleteImpactType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_IMPACT_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchImpactType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_IMPACT_URL}/${id}`).then(
            (response) =>
                commit('setImpactType', response.data)
        )
            .catch(error => error)

    },
    updateImpactType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_IMPACT_URL}/${data.id}`, data)
            .then((response) => {

                commit('setImpactType', response.data)
            }).catch(error => error)
    },

    fetchActeurTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_ACTEUR_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setActeurTypes', allData);
    },
    createActeurType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_ACTEUR_URL, data).then((response) => {
            commit('setActeurType', response.data)

        }).catch(error => error)
    },
    deleteActeurType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_ACTEUR_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchActeurType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_ACTEUR_URL}/${id}`).then(
            (response) =>
                commit('setActeurType', response.data)
        )
            .catch(error => error)

    },
    updateActeurType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_ACTEUR_URL}/${data.id}`, data)
            .then((response) => {

                commit('setActeurType', response.data)
            }).catch(error => error)
    },

    fetchOrganisationTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_ORGANISATION_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setOrganisationTypes', allData);
    },
    createOrganisationType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_ORGANISATION_URL, data).then((response) => {
            commit('setOrganisationType', response.data)

        }).catch(error => error)
    },
    deleteOrganisationType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_ORGANISATION_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchOrganisationType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_ORGANISATION_URL}/${id}`).then(
            (response) =>
                commit('setOrganisationType', response.data)
        )
            .catch(error => error)

    },
    updateOrganisationType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_ORGANISATION_URL}/${data.id}`, data)
            .then((response) => {

                commit('setOrganisationType', response.data)
            }).catch(error => error)
    },

    fetchApplicationTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_APPLICATION_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setApplicationTypes', allData);
    },
    createApplicationType: async ({ commit }, data) => {
        await httpClient.post(TYPE_APPLICATION_URL, data).then((response) => {
            commit('setApplicationType', response.data)

        }).catch(error => error)
    },
    deleteApplicationType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_APPLICATION_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchApplicationType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_APPLICATION_URL}/${id}`).then(
            (response) =>
                commit('setApplicationType', response.data)
        )
            .catch(error => error)

    },
    updateApplicationType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_APPLICATION_URL}/${data.id}`, data)
            .then((response) => {

                commit('setApplicationType', response.data)
            }).catch(error => error)
    },

    fetchBeneficiaireTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_BENEFICIAIRE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setBeneficiaireTypes', allData);
    },
    createBeneficiaireType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_BENEFICIAIRE_URL, data).then((response) => {
            commit('setBeneficiaireType', response.data)

        }).catch(error => error)
    },
    deleteBeneficiaireType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_BENEFICIAIRE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchBeneficiaireType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_BENEFICIAIRE_URL}/${id}`).then(
            (response) =>
                commit('setBeneficiaireType', response.data)
        )
            .catch(error => error)

    },
    updateBeneficiaireType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_BENEFICIAIRE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setBeneficiaireType', response.data)
            }).catch(error => error)
    },

    fetchFeuilleTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_FEUILLE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'].map(({ name }) => name))
            })
        }
        commit('setFeuilleTypes', allData);
    },
    createFeuilleType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_FEUILLE_URL, data).then((response) => {
            commit('setFeuilleType', response.data)

        }).catch(error => error)
    },
    deleteFeuilleType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_FEUILLE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchFeuilleType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_FEUILLE_URL}/${id}`).then(
            (response) =>
                commit('setFeuilleType', response.data)
        )
            .catch(error => error)

    },
    updateFeuilleType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_FEUILLE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setFeuilleType', response.data)
            }).catch(error => error)
    },

    fetchInitiativeTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_INITIATIVE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'].map(({ name }) => name))
            })
        }
        commit('setInitiativeTypes', allData);
    },
    createInitiativeType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_INITIATIVE_URL, data).then((response) => {
            commit('setInitiativeType', response.data)

        }).catch(error => error)
    },
    deleteInitiativeType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_INITIATIVE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchInitiativeType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_INITIATIVE_URL}/${id}`).then(
            (response) =>
                commit('setInitiativeType', response.data)
        )
            .catch(error => error)

    },
    updateInitiativeType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_INITIATIVE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setInitiativeType', response.data)
            }).catch(error => error)
    },

    fetchKpiTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_KPI_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setKpiTypes', allData);
    },
    createKpiType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_KPI_URL, data).then((response) => {
            commit('setKpiType', response.data)

        }).catch(error => error)
    },
    deleteKpiType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_KPI_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchKpiType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_KPI_URL}/${id}`).then(
            (response) =>
                commit('setKpiType', response.data)
        )
            .catch(error => error)

    },
    updateKpiType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_KPI_URL}/${data.id}`, data)
            .then((response) => {

                commit('setKpiType', response.data)
            }).catch(error => error)
    },

    fetchRefCardTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_REF_CARD_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setRefCardTypes', allData);
    },
    createRefCardType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_REF_CARD_URL, data).then((response) => {
            commit('setRefCardType', response.data)

        }).catch(error => error)
    },
    deleteRefCardType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_REF_CARD_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchRefCardType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_REF_CARD_URL}/${id}`).then(
            (response) =>
                commit('setRefCardType', response.data)
        )
            .catch(error => error)

    },
    updateRefCardType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_REF_CARD_URL}/${data.id}`, data)
            .then((response) => {

                commit('setRefCardType', response.data)
            }).catch(error => error)
    },

    fetchTaskTypes: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TYPE_TASK_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setTaskTypes', allData);
    },
    createTaskType: async ({ commit }, data) => {
        // var pa = 'configuration/type_organisations';
        await httpClient.post(TYPE_TASK_URL, data).then((response) => {
            commit('setTaskType', response.data)

        }).catch(error => error)
    },
    deleteTaskType: async ({ commit }, id) => {
        await httpClient.delete(`${TYPE_TASK_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchTaskType: async ({ commit }, id) => {
        await httpClient.get(`${TYPE_TASK_URL}/${id}`).then(
            (response) =>
                commit('setTaskType', response.data)
        )
            .catch(error => error)

    },
    updateTaskType: async ({ commit }, data) => {
        await httpClient.put(`${TYPE_TASK_URL}/${data.id}`, data)
            .then((response) => {

                commit('setTaskType', response.data)
            }).catch(error => error)
    },


};

export default actions;