const mutations = {
    setFdrs: (state, payload) => {
        state.fdrs = payload;
    }
    , setFdr: (state, payload) => {
        state.fdr = payload;
    },
    setFdrComite: (state, payload) => {
        state.comite = payload;
    },
    setFdrInitiatives: (state, payload) => {
        state.initiatives = payload;
    },
    setFdrPlans: (state, payload) => {
        state.transformationPlans = payload;
    },
    setFdrCouvertures: (state, payload) => {
        state.couvertures = payload;
    }
};
export default mutations;