import { httpClient } from "../../../../helpers/services/HttpClient";
import { IMPACT_URL } from '@/helpers/constURL'

const actions = {
    fetchAllImpacts: async ({ commit }, page = IMPACT_URL) => {
        const response = await httpClient.get(page)
        const data = response.data;
        window.console.log(data['hydra:member'])
        commit('setImpacts', data['hydra:member']);
        commit('setView', data['hydra:view']);
        commit('setTotalItems', data['hydra:totalItems']);
    },
    deleteImpact: async ({ commit }, id) => {
        const response = await httpClient.delete(`${IMPACT_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    createImpact: async ({ commit }, data) => {
        const response = await httpClient.post(IMPACT_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        // window.console.log(response)
        commit('setImpact', response.data)

        return response.data;

    },
    fetchImpact: async ({ commit }, id) => {
        const response = await httpClient.get(`${IMPACT_URL}/${id}`)
            .catch(error => error)
        commit('setImpact', response.data)
        window.console.log(response.data)

    },
    updateImpact: async ({ commit }, data) => {
        const response = await httpClient.put(`${IMPACT_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setImpact', response.data)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },


};

export default actions;