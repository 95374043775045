import { httpClient } from "@/helpers/services/HttpClient";
import { Fluxe_URL } from '@/helpers/constURL'

import { concat } from "lodash";

const actions = {
    fetchAllFluxex: async ({ commit }) => {
        const response = await httpClient.get(Fluxe_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${Fluxe_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setFluxes', allData);
    }
    ,
    fetchFluxe: async ({ commit }, id) => {
        const response = await httpClient.get(`${Fluxe_URL}/${id}`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setFluxe', response.data)

    },
    createFluxe: async ({ commit }, data) => {
        const response = await httpClient.post(Fluxe_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        commit('setFluxe', response.data)
        return response.data;
    },
    updateFluxe: async ({ commit }, data) => {
        const response = await httpClient.put(`${Fluxe_URL}/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteFluxe: async ({ commit }, id) => {
        const response = await httpClient.delete(`${Fluxe_URL}/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    fetchFluxObjects: async ({ commit }, id) => {
        await httpClient.get(`${Fluxe_URL}/${id}/objets`).then((response) => {
            commit('setFluxObjects', response.data)
        }
        ).catch((error) => {
            window.console.log(error)
        })
    }




};

export default actions;