const state = {
    statuses: [],
    status: {},
    statusTasks: [],
    statusApplications: [],
    statusComites: [],
    statusComposants: [],
    statusContributeurs: [],
    statusFeuilles: [],
    statusInitiatives: [],
    statusInscriptions: [],
    statusPhases: [],
    statusRefCards: [],
    statusSemaines: [],
    statusSessions: [],
    statusVisions: []

}

export default state;