const mutations = {
    setComposants: (state, payload) => {
        state.composants = payload;
    }
    , setComposant: (state, payload) => {
        state.composant = payload;
    },
    setComposantTransformation: (state, payload) => {
        state.transformation = payload;
    },
    setComposantVersions: (state, payload) => {
        state.versions = payload;
    },
    setComposantApplications: (state, payload) => {
        state.applications = payload;
    },
    setComposantCapacites: (state, payload) => {
        state.capacites = payload;
    },
    setComposantCadres: (state, payload) => {
        state.cadres = payload;
    }
};
export default mutations;