import Axios from 'axios';
// import { handleError } from './apiErrorHandler';
// import store from "@/store";
// import { commit } from "vuex";
// import { updateToken, updateRefreshToken } from './AuthService';
const API_URL = 'https://api.raleo.fr/api';
export const IMG_URL = 'https://api.raleo.fr';
// const API_URL = 'http://172.16.2.112:8101/api/';

// const API_URL = 'http://172.16.2.112:8101/api/';
// export const IMG_URL = 'http://172.16.2.112:8101';

const UNAUTHORIZED_MESSAGE = 'Expired JWT Token';
const TOKEN_NOT_FOND = 'JWT Token not found';
const INVALID_TOKEN = 'Invalid JWT Token';
const EXPIRED_REFRESH_TOKEN = "An authentication exception occurred."
const HTTP_UNAUTHORIZED = 401


export const instance = Axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
}, (error) => {
  Promise.reject(error)
});

instance.interceptors.response.use(
  response => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {

      if (error.response.data.code === HTTP_UNAUTHORIZED)
        if (error.response.data.message === UNAUTHORIZED_MESSAGE || error.response.data.message === TOKEN_NOT_FOND) {
          try {
            return await instance.post('/token/refresh', { refresh_token: localStorage.getItem('refreshToken') || '' })
              .then((response) => {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('refreshToken', response.data.refresh_token)
                return instance({
                  ...originalConfig,
                  headers: {
                    ...originalConfig.headers,
                    Authorization: "Bearer " + response.data.token
                  }
                });
              })

          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
        else if (error.response.data.message === EXPIRED_REFRESH_TOKEN || error.response.data.message === INVALID_TOKEN) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          window.location.href = "/login"
        }
        else
          return Promise.reject(error);

    }
    return Promise.reject(error);

  }

);



