import { httpClient } from "../../../helpers/services/HttpClient";

const state = {
    status: [],
    visionStatus: [],
    fdrStatus: [],
    initiativeStatus: [],
    contributeurStatus: [],
    taskStatus: [],
    comiteStatus: [],
    periodicite : [],
    inscriptionStatus : []
}
const getters = {
    STATUS: state => state.status,
    VISIONS_STATUS: state => state.visionStatus,
    FDR_STATUS: state => state.fdrStatus,
    INITIATIVE_STATUS: state => state.initiativeStatus,
    CONTRIBUTEUR_STATUS: state => state.contributeurStatus,
    TASK_STATUS: state => state.taskStatus,
    COMITE_STATUS: state => state.comiteStatus,
    PERIODICITE: state => state.periodicite,
    INSCRIPTION_STATUS: state => state.inscriptionStatus,
   
}

const mutations = {
    setStatus: (state, payload) => {
        state.status = payload;
    },
    setVisionsStatus: (state, payload) => {
        state.visionStatus = payload;
    },
    setFdrStatus: (state, payload) => {
        state.fdrStatus = payload
    },
    setInitiativeStatus: (state, payload) => {
        state.initiativeStatus = payload
    },
    setContributeurStatus: (state, payload) => {
        state.contributeurStatus = payload
    },
    setTaskStatus: (state, payload) => {
        state.taskStatus = payload
    },
    setComiteStaus: (state, payload) => {
        state.comiteStatus = payload
    },
    setPeriodicite: (state, payload) => {
        state.periodicite = payload
    },
    setInscriptionStaus: (state, payload) => {
        state.inscriptionStatus = payload
    },

}

const actions = {
    fetchAllStatus: async ({ commit, state }) => {
        if (state.status.length) return;
        const res = await httpClient.get('/status').catch(err => window.console.log(err));
        window.console.log(res.data);
        commit('setStatus', res.data);
    },
    fetchVisionStatus: async ({ commit }) => {
        if (state.visionStatus.length) return;
        const response = await httpClient.get('/status').catch(error => window.console.log(error))
        const data = response.data.status.visions;
         commit('setVisionsStatus', data)

    },
    fetchFdrStatus: async ({ commit }) => {
        if (state.fdrStatus.length) return;
        const response = await httpClient.get('/status').catch(error => window.console.log(error))
        const data = response.data.status.fdr;
       
        commit('setFdrStatus', data)

    },
    fetchInitiativeStatus: async ({ commit }) => {
        if (state.initiativeStatus.length) return;
        const response = await httpClient.get('/status').catch(error => window.console.log(error))
        const data = response.data.status.initiative; 
        commit('setInitiativeStatus', data)

    },
    fetchContributeurStatus: async ({ commit }) => {
        if (state.contributeurStatus.length) return;
        const response = await httpClient.get('/status').catch(error => window.console.log(error))
        const data = response.data.status.contributeurs;  
        commit('setContributeurStatus', data)

    },
    fetchTaskStatus: async ({ commit }) => {
        if (state.taskStatus.length) return;
        await httpClient.get('/status').then((response)=> {
            commit('setTaskStatus', response.data.status.tasks)
        }
       ).catch(error => window.console.log(error))
     

    },
    fetchComiteStatus: async ({ commit }) => {
        if (state.comiteStatus.length) return;
        const response = await httpClient.get('/status').catch(error => window.console.log(error))
        const data = response.data.status.comite;
        commit('setComiteStaus', data)

    },
    fetchPeriodicite: async ({ commit }) => {
        const response = await httpClient.get('/periodicite').catch(error => window.console.log(error))
        const data = response.data.periodiciteComite;
        commit('setPeriodicite', data)

    },
    fetchInscriptionStatus: async ({ commit }) => {
        if (state.comiteStatus.length) return;
        await httpClient.get('/configuration/status_inscriptions').then((response)=> {
            const data = response.data["hydra:member"].map(data => data.status)
            commit('setInscriptionStaus', data)

        }).catch(error => window.console.log(error))
        
    },


}

const StatusModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default StatusModule;