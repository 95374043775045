const mutations = {
    setPrincipes: (state, payload) => {
        state.principes = payload;
    },
    setPrincipe: (state, payload) => {
        state.principe = payload;
    },
    setCadre: (state, payload) => {
        state.cadre = payload;
    },
};
export default mutations;