import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllBeneficiares: async ({ commit }) => {
        const response = await httpClient.get('referentiel/beneficiares')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/beneficiares?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        window.console.log(allData, "ben")
        commit('setBeneficiares', allData);
    }
    ,
    fetchBeneficiare: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/beneficiares/${id}`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setBeneficiare', response.data)

    },
    createBeneficiare: async ({ commit }, data) => {
        const response = await httpClient.post('referentiel/beneficiares', data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        window.console.log(response)
        commit('setBeneficiare', response.data)
        return response.data;
    },
    updateBeneficiare: async ({ commit }, data) => {
        const response = await httpClient.put(`referentiel/beneficiares/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteBeneficiare: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/beneficiares/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },

};

export default actions;