import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash"
import { COMITE_URL } from '@/helpers/constURL'
const actions = {
    fetchAllComites: async ({ commit }) => {


        const response = await httpClient.get(COMITE_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${COMITE_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        } 
        commit('setComites', allData);
    },
    updateComite: async ({ commit }, data) => {
        window.console.log(data, 'updaetmembers')
        const response = await httpClient.put(`${COMITE_URL}/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
    },
    deleteComite: async ({ commit }, id) => {
        const response = await httpClient.delete(`${COMITE_URL}/${id}`)
            .catch(error => error)
        commit('setComites', response.data)
        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchComite: async ({ commit }, id) => {
        const response = await httpClient.get(`${COMITE_URL}/${id}`)
            .catch(error => error)
         
        commit('setComite', response.data)

    },
    createComite: async ({ commit }, data) => {
        const response = await httpClient.post(COMITE_URL, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
        commit('setComite', response.data)
        return response.data;

    },
    fetchMembers: async ({ commit }, id) => {
        const response = await httpClient.get(`${COMITE_URL}/${id}/members`)
            .catch(error => error)
         
        commit('setMembers', response.data)

    },

    fetchinscription: async ({ commit }, id) => {
        const response = await httpClient.get(`${COMITE_URL}/${id}/dossiers`)
            .catch(error => error)
     
        commit('setInscriptions', response.data)

    },

}

export default actions;