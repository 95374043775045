const mutations = {
    setTasks: (state, payload) => {
        state.tasks = payload;
    },
    setTask: (state, payload) => {
        state.task = payload;
    },
    setView: (state, view) => {
        Object.assign(state, { view });
    },
    setTotalItems: (state, payload) => {
        state.totalItems = payload;
    },
    setAllTasks: (state, payload) => {
        state.allTasks = payload
    },
    setTeamTasks: (state, payload) => {
        state.teamTasks = payload
    }

};
export default mutations;