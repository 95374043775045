import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { APPLICATION_URL, APPLICATION_FILE_URL } from '@/helpers/constURL'

const actions = {


    fetchAllApplications: async ({ commit }, page = APPLICATION_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${APPLICATION_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setApplications', allData);
    },
    createApplication: async ({ commit }, data) => {
        await httpClient.post(APPLICATION_URL, data).then((response) => {
            commit('setApplication', response.data)
        }).catch(error => error)
    },
    deleteApplication: async ({ commit }, id) => {
        await httpClient.delete(`${APPLICATION_URL}/${id}`)
            .catch(error => error)
        commit
        window.console.log()



    },
    fetchApplication: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}`).then(
            (response) =>
                commit('setApplication', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationBeneficaires: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/beneficiaires`).then(
            (response) =>
                commit('setApplicationBeneficaires', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationCapacites: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/capacites`).then(
            (response) =>
                commit('setApplicationCapacites', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationProcessus: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/processus`).then(
            (response) =>
                commit('setApplicationProcessus', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationFonctions: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/fonctions`).then(
            (response) =>
                commit('setApplicationFonctions', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationComposants: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/composants`).then(
            (response) =>
                commit('setApplicationComposants', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationTransformations: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/transformation`).then(
            (response) =>
                commit('setApplicationTransformations', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationApi: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/api_exposes`).then(
            (response) =>
                commit('setApplicationApi', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationReferents: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/referents`).then(
            (response) =>
                commit('setApplicationReferents', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationTraitements: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/traitements`).then(
            (response) =>
                commit('setApplicationTraitements', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationObjets: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/objets`).then(
            (response) =>
                commit('setApplicationObjets', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationFluxs: async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/flux`).then(
            (response) =>
                commit('setApplicationFluxs', response.data)

        )
            .catch(error => error)

    },
    fetchApplicationCadres : async ({ commit }, id) => {
        await httpClient.get(`${APPLICATION_URL}/${id}/cadres`).then(
            (response) =>
                commit('setApplicationCadres', response.data)

        )
            .catch(error => error)

    },
    updateApplication: async ({ commit }, data) => {
        await httpClient.put(`${APPLICATION_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    },
    // actions for file applications
    createFileApplication: async ({ commit }, data) => {
        await httpClient.post(APPLICATION_FILE_URL, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => commit)
            .catch(error => error)

    },

    deleteFileApplication: async ({ commit }, id) => {
        await httpClient.delete(`${APPLICATION_FILE_URL}/${id}`).then(() => commit)
            .catch(error => error)

    },

};

export default actions;