const getters = {
    INITIATIVES: state => state.initiatives,
    VIEW: state => state.view,
    TOTAL_ITEMS: state => state.totalItems,
    INITIATIVE: state => state.initiative,
    COMITE: state => state.comite,
    IMPUTATIONS: state => state.imputations,
    TASKS: state => state.tasks,
    IMPACTS: state => state.impacts,
    IMPUTATIONS_BY_DIRECTIONS: state => state.imputationsByDirections,

}

export default getters;