import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { PATTERN_URL} from '@/helpers/constURL'
const actions = {
    fetchAllPatterns: async ({ commit }, page = PATTERN_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${PATTERN_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setPatterns', allData);
    },
    createPattern: async ({ commit }, data) => {
       await httpClient.post(PATTERN_URL, data).then((response) => {
            commit('setPattern', response.data)
           window.console.log(response)

            
        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deletePattern: async ({ commit }, id) => {
        const response = await httpClient.delete(`${PATTERN_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchPattern: async ({ commit }, id) => {
        await httpClient.get(`${PATTERN_URL}/${id}`).then(
            (response) => 
                commit('setPattern', response.data)

        )
            .catch(error => error)

    },
    updatePattern: async ({ commit }, data) => {
     await httpClient.put(`${PATTERN_URL}/${data.id}`, data)
        .then((response) => {
            // commit('setPattern', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    }

};

export default actions;