import { httpClient } from "../../../helpers/services/HttpClient";
import { concat } from "lodash"
const state = {
    responsables: [],
    users: [],
    user: [],
    hierachie: [],
    function: [],
}

const getters = {
    RESPONSABLES: state => state.responsables,
    USERS: state => state.users,
    USER: state => state.user,
    HIERACHIE: state => state.hierachie,
    function: state => state.function,

}

const mutations = {
    setResponsables: (state, payload) => {
        state.responsables = payload;
    },
    setUsers: (state, payload) => {
        state.users = payload;
    },
    setUser: (state, payload) => {
        state.user = payload;
    },
    setHierachie: (state, payload) => {
        state.hierachie = payload;
    },
    setfunction: (state, payload) => {
        state.function = payload;
    }
}

const actions = {
    fetchAllResponsables: async ({ commit }) => {
        // if (state.responsables.length) return;
        // const res = await httpClient.get('/users/all').catch(err => window.console.log(err));
        // //const data = res.data['hydra:member'];
        // commit('setResponsables', res.data);
        var totalItems = 1
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`users?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setResponsables', allData);
    },
    fetchUser: async ({ commit }, id) => {
        const response = await httpClient.get(`/users/${id}`)
            .catch(error => error)
        commit('setUser', response.data)

    },
    fetchAllUsers: async ({ commit }) => {
        var totalItems = 1
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`users?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setUsers', allData);
    },
    createUsers: async ({ commit }, data) => {
        await httpClient.post('/users', data).then((response) => {
            commit('setUser', response.data);

        })
            .catch(error => error)
    },
    updateUser: async ({ commit }, data) => {
        await httpClient.put(`/users/${data.id}`, data)
            .then((response) => {
                commit('setUser', response.data);

            })
            .catch(error => error)
    },
    deleteUsers: async ({ commit }, id) => {
        await httpClient.delete(`/users/${id}`)
            .catch(error => error)
        commit
    },
    fetchfunction: async ({ commit }, data) => {
        await httpClient.get('/users/functions', data)
            .then((response) => {
                commit('setfunction', response.data);
            })
            .catch(error => error)

    },
    fetchierachie: async ({ commit }, data) => {
        await httpClient.get('/users/hierarchie', data)
            .then((response) => {
                commit('setHierachie', response.data);
            })
            .catch(error => error)
    },
    createImage: async ({ commit }, data) => {
        await httpClient.post(`/users/${data.id}/image`, data.file,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                commit('setUser', response.data)
            })
            .catch(error => error)



    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}