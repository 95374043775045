import { httpClient } from "@/helpers/services/HttpClient";
// import { concat } from "lodash";
import { FINANCE_INITIATIVE_URL } from "@/helpers/constURL";
const actions = {
    // fetchAllFinanceInitiatives: async ({ commit }, page = FINANCE_INITIATIVE_URL) => {

    //     const response = await httpClient.get(page)
    //     const totalItems = response.data['hydra:totalItems'];
    //     var allData = []
    //     for (let i = 1; i <= (totalItems / 10) + 1; i++) {
    //         await httpClient.get(`FinanceInitiatives?page=${i}`).then((response) => {
    //             allData = concat(allData, response.data['hydra:member'])
    //         })
    //     }
    //     commit('setFinanceInitiatives', allData);
    // },
    createFinanceInitiative: async ({ commit }, data) => {
        await httpClient.post(FINANCE_INITIATIVE_URL, data).then((response) => {
            commit('setFinanceInitiative', response.data)

        }).catch(error => error)
    },
    deleteFinanceInitiative: async ({ commit }, id) => {
        await httpClient.delete(`${FINANCE_INITIATIVE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchFinanceInitiative: async ({ commit }, id) => {
        await httpClient.get(`${FINANCE_INITIATIVE_URL}/${id}`).then(
            (response) =>
                commit('setFinanceInitiative', response.data)
        )
            .catch(error => error)

    },
    updateFinanceInitiative: async ({ commit }, data) => {
        await httpClient.put(`${FINANCE_INITIATIVE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setFinanceInitiative', response.data)



            }).catch(error => error)
    }

};

export default actions;