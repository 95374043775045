import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllPatrimoines: async ({ commit }) => {
        const response = await httpClient.get('referentiel/patrimoines')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/patrimoines?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setPatrimoine', allData);
    }
    ,
    fetchApplications: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/applications`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setapplication', response.data)

    }
    ,
    fetchFdr: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/feuilles`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setFdr', response.data)

    }
    ,
    fetchTransformation: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/transformation`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setTransformation', response.data)

    }
    ,
    fetchPatrimoine: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setPatrimoines', response.data)

    },
    fetchReferents: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/referents`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setReferents', response.data)

    },
    fetchProcessus: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/processus`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setProcessus', response.data)


    },
    fetchComposants: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/composants_techniques`)
            .catch(error => error)
        commit('setComposants', response.data)


    },
    fetchRef: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/patrimoines/${id}/cadres`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setRef', response.data)


    },

    createPatrimoines: async ({ commit }, data) => {
        const response = await httpClient.post('referentiel/patrimoines', data)
        commit('setPatrimoines', response.data)
        window.console.log(response.data, 'rsp')

        window.console.log(commit)

    },
    updatePatrimoines: async ({ commit }, data) => {
        const response = await httpClient.put(`referentiel/patrimoines/${data.id}`, data)
            .catch(error => error)
        window.console.log(commit)
        window.console.log(response)
        commit("setPatrimoines", response.data)

    },
    createRef: async ({ commit }, data) => {
        await httpClient.post('referentiel/referents', data).then((response) => {
            commit('setPatrimoine', response.data)
            window.console.log(response)
        }).catch(error => error)
        window.console.log(commit)

    },
    deleteRef: async ({ commit }, id) => {
        const response = await httpClient.delete('referentiel/referents/' + id)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    deletePatrimoine: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/patrimoines/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    // createOrganisation: async ({ commit }, data) => {
    //     const response = await httpClient.post('referentiel/organisations', data)
    //         .catch(error => error)
    // },

};

export default actions;