
import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { TASK_URL } from '@/helpers/constURL'
const actions = {

    fetchAllData: async ({ commit }) => {
        const response = await httpClient.get(TASK_URL)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TASK_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setAllTasks', allData);


    },
    fetchTeamTasks: async ({ commit }) => {
        let totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${TASK_URL}/team?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setTeamTasks', allData);


    },
    fetchTask: async ({ commit }, id) => {
        const response = await httpClient.get(`${TASK_URL}/${id}`)
            .catch(error => error)
        commit('setTask', response.data)

    },
    createTask: async ({ commit }, data) => {
        const response = await httpClient.post(TASK_URL, data)
            .catch(error => error)

        commit('setTask', response.data)

        return response.data;

    },
    deleteTask: async ({ commit }, id) => {
        await httpClient.delete(`${TASK_URL}/${id}`)
            .catch(error => error)
        commit
    },
    updateTask: async ({ commit }, data) => {
        await httpClient.put(`${TASK_URL}/${data.id}`, data).then((response) => {
            commit('setTask', response.data)
        })
            .catch(error => error)


    },

};

export default actions;