const mutations = {
    setTeam: (state, payload) => {
        state.teams = payload;
    },
    setTeams: (state, payload) => {
        state.team = payload;

    },
    setMembers: (state, payload) => {
        state.memebers = payload;

    }
};
export default mutations;