import layout from "@/layout";

const analytics = {
  path: "/analytiques",
  component: layout,
  redirect: "/analytiques/patrimoine",
  name: "Sécurité",
  meta: {
    title: "Analytiques",
    navigationName: "Analytiques",
    iconClass: "mdi-chart-arc",
    requiresAuth: true,

  },
  children: [

    {
      path: "securite",
      name: "Sécurité",
      component: () => import("@/pages/analytics/Security/Index"),
      meta: {
        title: "Sécurité",
        navigationName: "Sécurité",
        iconClass: "mdi mdi-monitor",
      },
    },
    {
      path: "projet",
      name: "Projet",
      component: () => import("@/pages/analytics/Project/Index"),

      meta: {
        title: "Projet",
        navigationName: "Projet",
        iconClass: "mdi mdi-format-list-checks ",
      },
    },
    {
      path: "patrimoine",
      name: "Patrimoine",
      component: () => import("@/pages/analytics/Patrimoine/Index"),
      meta: {
        title: "Patrimoine",
        navigationName: "Patrimoine",
        iconClass: "mdi mdi-earth",
      },
    },
  ]
};

export default analytics;
