import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { CURRENCY_URL } from "@/helpers/constURL";
const actions = {
    fetchAllCurrencies: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${CURRENCY_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setCurrencies', allData);
    },
    createCurrency: async ({ commit }, data) => {
        await httpClient.post(CURRENCY_URL, data).then((response) => {
            commit('setCurrency', response.data)

        }).catch(error => error)
    },
    deleteCurrency: async ({ commit }, id) => {
        await httpClient.delete(`${CURRENCY_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchCurrency: async ({ commit }, id) => {
        await httpClient.get(`${CURRENCY_URL}/${id}`).then(
            (response) =>
                commit('setCurrency', response.data)
        )
            .catch(error => error)

    },
    updateCurrency: async ({ commit }, data) => {
        await httpClient.put(`${CURRENCY_URL}/${data.id}`, data)
            .then((response) => {

                commit('setCurrency', response.data)



            }).catch(error => error)
    }

};

export default actions;