import layout from "@/layout"; 
const profile = {
    path: "/account",
    component: layout,
    redirect: "/account/profile",
    name: "account",
    meta: {
        title: "Compte",
        navigationName: "Compte",
        iconClass: "mdi-chart-arc",
        requiresAuth: true,
    },
    children: [

        {
            path: "profile",
            name: "Profile",
               component: () => import("@/pages/profile/Index"),
            meta: {
                title: "Profile",
                navigationName: "Profile",
                iconClass: "mdi mdi-monitor",
            },
        },
    ]

};

export default profile;
