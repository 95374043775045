import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {


    fetchAllObjets: async ({ commit }, page = 'referentiel/objets') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/objets?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setObjets', allData);
    },
    createObjet: async ({ commit }, data) => {
        await httpClient.post('referentiel/objets', data).then((response) => {
            commit('setObjet', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        // window.console.clear()
    },
    deleteObjet: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/objets/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchObjet: async ({ commit }, id) => {
        await httpClient.get(`referentiel/objets/${id}`).then(
            (response) =>
                commit('setObjet', response.data)

        )
            .catch(error => error)

    },
    updateObjet: async ({ commit }, data) => {
        await httpClient.put(`referentiel/objets/${data.id}`, data)
            .then((response) => {
                window.console.log(response)


            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;