const mutations = {
    setPatrimoine: (state, payload) => {
        state.patrimoines = payload;
    },
    setapplication : (state, payload) => {
        state.application = payload;
    },
    setPatrimoines :(state, payload) => {
        state.patrimoine = payload;
    },
    setReferents  :(state, payload) => {
        state.referents = payload;
    },
    setProcessus  :(state, payload) => {
        state.processus = payload;
    },
    setComposants : (state, payload) => {
    state.composant = payload;
},
    setFdr  :(state, payload) => {
        state.fdr = payload;
    },
    setTransformation  :(state, payload) => {
        state.transformation = payload;
    },
    setRef  :(state, payload) => {
        state.cadres = payload;
    },

} ;
export default mutations;