import {  httpClient } from "@/helpers/services/HttpClient";
import {concat} from "lodash";

const actions = {
    fetchAllEntities: async ({ commit }) => {
        const response = await httpClient.get('referentiel/entites')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/entites?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
     
        commit('setEntity', allData);
    },
    fetchEntitie: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/entites/${id}`)
            .catch(error => error)
        commit('setEntitie', response.data)

    },
    fetchOrganisation: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/entites/${id}/organisations`)
            .catch(error => error)
        commit("setOrganisation", response.data)

    },
    fetchActeurs: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/entites/${id}/acteurs`)
            .catch(error => error)
        commit("setActeurs", response.data)

    },
    deleteEntity: async ({ commit }, id) => {
          await httpClient.delete(`referentiel/entites/${id}`)
            .catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },

    createEntity: async ({ commit }, data) => {
         const response = await httpClient.post('referentiel/entites', data)
             .catch(error => error)
        commit("setEntitie", response.data)



     },
    updateEntity: async ({ commit }, data) => {
        const response = await httpClient.put(`referentiel/entites/${data.id}`, data)
            .catch(error => error)
        commit("setOrganisation", response.data)
        commit("setEntitie",response.data)

    },


};

export default actions;