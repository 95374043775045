import { httpClient } from "@/helpers/services/HttpClient";
import { STATS_URL } from "@/helpers/constURL";
const state = {
    portefeuils: null,
    progresses: {
        initiatives: {
            percentage: 0
        },
        livrables: {
            percentage: 0
        },
        objectifs: {
            percentage: 0
        },
        fdr: {
            percentage: 0
        },
        applications: {
            percentage: 0
        },
        composants: {
            percentage: 0
        },
        capacites: {
            percentage: 0
        },
        patterns: {
            percentage: 0
        },
        patrimoines: {
            percentage: 0
        }
    }
}

const getters = {
    PORTEFUEILS: state => state.portefeuils,
    PROGRESSES: state => state.progresses
}

const mutations = {
    setPortefeuils: (state, payload) => {
        state.portefeuils = payload;
    },
    setProgresses: (state, payload) => {
        state.progresses = {
            ...state.progresses
            , ...payload
        }
    }
}


const actions = {
    fetchDashboardData: async ({ commit }) => {
        await httpClient.get(STATS_URL)
            .then(({ data }) => {
                const keys = Object.keys(data.stats);
                keys.map(key => {
                    data[key] = Object.values(data.stats[key]);
                })
                commit('setPortefeuils', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchInitiativeProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/initiatives`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    }
    ,
    fetchFdrProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/fdr`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchLivrableProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/livrables`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchObjectifsProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/objectifs`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchApplicationsProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/applications`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchComposantsProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/composants`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    }
    ,
    fetchCapacitesProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/capacites`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    },
    fetchPatrimoinesProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/patrimoines`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    }
    ,
    fetchPatternsProgress: async ({ commit }) => {
        await httpClient.get(`${STATS_URL}/cadres`)
            .then(({ data }) => {
                // window.console.log(data);
                commit('setProgresses', data);
            })
            .catch(err => window.console.log(err))
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}