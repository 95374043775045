import router from '@/router/index.js';


const views = {
  namespaced: true,
  state: () => ({
    activeView: window.sessionStorage.viewIndex ? window.sessionStorage.viewIndex : 0,
    //for capacite mitier active view
    capaciteMiterActiveView: window.sessionStorage.capaciteMiterActiveView ? window.sessionStorage.capaciteMiterActiveView : 0,
    //for processus mitier active view
    processusMiterActiveView: window.sessionStorage.processusMiterActiveView ? window.sessionStorage.processusMiterActiveView : 0,
    //for capacite si active view
    capaciteSiActiveView: window.sessionStorage.capaciteSiActiveView ? window.sessionStorage.capaciteSiActiveView : 0,
    //for application active view
    applicationActiveView: window.sessionStorage.applicationActiveView ? window.sessionStorage.applicationActiveView : 0,
    //for capacite technique active view
    capaciteTechniqueActiveView: window.sessionStorage.capaciteTechniqueActiveView ? window.sessionStorage.capaciteTechniqueActiveView : 0,
    //for composant technique active view
    composantTechniqueActiveView: window.sessionStorage.composantTechniqueActiveView ? window.sessionStorage.composantTechniqueActiveView : 0,
    //for patrimoine active view
    patrimoineActiveView: window.sessionStorage.patrimoineActiveView ? window.sessionStorage.patrimoineActiveView : 0,



  }),
  mutations: {
    changeView(state, payload) {
      state.activeView = payload;
    },
    changeCapaciteMiterActiveView(state, payload) {
      state.capaciteMiterActiveView = payload;
    },
    changeProcessusMiterActiveView(state, payload) {
      state.processusMiterActiveView = payload;
    },
    changeCapaciteSiActiveView(state, payload) {
      state.capaciteSiActiveView = payload;
    },
    changeApplicationActiveView(state, payload) {
      state.applicationActiveView = payload;
    },
    changeCapaciteTechniqueActiveView(state, payload) {
      state.capaciteTechniqueActiveView = payload;
    },
    changeComposantTechniqueActiveView(state, payload) {
      state.composantTechniqueActiveView = payload;
    },
    changePatrimoineActiveView(state, payload) {
      state.patrimoineActiveView = payload;
    }


  },
  actions: {
    changeView({ commit }, payload) {
      window.console.log('prev route', router.history);
      window.sessionStorage.setItem('viewIndex', payload);
      commit("changeView", payload);
    },
    changeCapaciteMiterActiveView({ commit }, payload) {
      window.sessionStorage.setItem('capaciteMiterActiveView', payload);
      commit("changeCapaciteMiterActiveView", payload);
    },
    changeProcessusMiterActiveView({ commit }, payload) {
      window.sessionStorage.setItem('processusMiterActiveView', payload);
      commit("changeProcessusMiterActiveView", payload);
    },
    changeCapaciteSiActiveView({ commit }, payload) {
      window.sessionStorage.setItem('capaciteSiActiveView', payload);
      commit("changeCapaciteSiActiveView", payload);
    },
    changeApplicationActiveView({ commit }, payload) {
      window.sessionStorage.setItem('applicationActiveView', payload);
      commit("changeApplicationActiveView", payload);
    },
    changeCapaciteTechniqueActiveView({ commit }, payload) {
      window.sessionStorage.setItem('capaciteTechniqueActiveView', payload);
      commit("changeCapaciteTechniqueActiveView", payload);
    },
    changeComposantTechniqueActiveView({ commit }, payload) {
      window.sessionStorage.setItem('composantTechniqueActiveView', payload);
      commit("changeComposantTechniqueActiveView", payload);
    },
    changePatrimoineActiveView({ commit }, payload) {
      window.sessionStorage.setItem('patrimoineActiveView', payload);
      commit("changePatrimoineActiveView", payload);
    }

  },
  getters: {
    ACTIVE_VIEW: state => state.activeView,
    CAPACITE_MITER_ACTIVE_VIEW: state => state.capaciteMiterActiveView,
    PROCESSUS_MITER_ACTIVE_VIEW: state => state.processusMiterActiveView,
    CAPACITE_SI_ACTIVE_VIEW: state => state.capaciteSiActiveView,
    APPLICATION_ACTIVE_VIEW: state => state.applicationActiveView,
    CAPACITE_TECHNIQUE_ACTIVE_VIEW: state => state.capaciteTechniqueActiveView,
    COMPOSANT_TECHNIQUE_ACTIVE_VIEW: state => state.composantTechniqueActiveView,
    PATRIMOINE_ACTIVE_VIEW: state => state.patrimoineActiveView

  },
};

export default views;
