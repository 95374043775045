import { httpClient } from "../../../../helpers/services/HttpClient";
import { INVITE_URL } from '@/helpers/constURL'
const actions = {
    fetchAllInvites: async ({ commit }) => {
        const res = await httpClient.get(INVITE_URL).catch(err => window.console.log(err));
        const data = res.data['hydra:member'];
        window.console.log(data, "Invite");
        commit('setInvites', data);

    },
    updateInvite: async ({ commit }, data) => {
        const response = await httpClient.put(`${INVITE_URL}/${data.id}`, data)
            .catch(error => error)
        commit('setInvite', response.data);

        window.console.log(commit)
        window.console.log(response)
    },
    deleteInvite: async (id) => {
        window.console.log(id)
        await httpClient.delete(`${INVITE_URL}/${id}`)
            .catch(error => error)

    },
    createInvite: async ({ commit }, data) => {
        const response = await httpClient.post(INVITE_URL, data)
            .catch(error => error)
        window.console.log(commit)
        commit('setInvite', response.data)
        window.console.log(response)
        return response.data;

    },


}

export default actions;