import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllCapaciteMetiers: async ({ commit }, page = 'referentiel/capacite_metiers') => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`referentiel/capacite_metiers?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        } 
        commit('setCapaciteMetiers', allData);
    },
    createCapaciteMetier: async ({ commit }, data) => {
       await httpClient.post('referentiel/capacite_metiers', data).then((response) => {
           window.console.log(response)
           actions.fetchAllCapaciteMetiers({ commit })

           commit('setCapaciteMetier', response.data)
        }).catch(error => error)
        window.console.log(commit)
    },

    fetchCapacité: async ({ commit }, id) => {
        const response = await httpClient.get(`referentiel/capacite_metiers/${id}/processus`)
            .catch(error => error)
        window.console.log(response.data)
        commit('setProcessus', response.data)

    },
    deleteCapaciteMetier: async ({ commit }, id) => {
        const response = await httpClient.delete(`referentiel/capacite_metiers/${id}`)
            .catch(error => error)
        actions.fetchAllCapaciteMetiers({ commit })
        commit('setCapaciteMetier', response.data)

        window.console.log(commit)
        window.console.log(response)
    },
    fetchCapaciteMetier: async ({ commit }, id) => {
        await httpClient.get(`referentiel/capacite_metiers/${id}`).then(
            (response) => 
                commit('setCapaciteMetier', response.data)

        )
            .catch(error => error)

    },
    updateCapacite: async ({ commit }, data) => {
     await httpClient.put(`referentiel/capacite_metiers/${data.id}`, data)
        .then((response) => {
             window.console.log(response)
            actions.fetchAllCapaciteMetiers({ commit })

            commit('setCapaciteMetier', response.data)

        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
 
    }

};

export default actions;