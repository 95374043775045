import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";

const actions = {
    fetchAllVisions: async ({ commit }) => {
        const response = await httpClient.get('strategy/visions')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`strategy/visions?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setVisions', allData);

    }
    ,
    fetchVision: async ({ commit }, id) => {
        await httpClient.get(`strategy/visions/${id}`).then((response) =>
            commit('setVisionSingle', response.data)
        )
            .catch(error => error)
    }
    , fetchVisionObjectifs: async ({ commit }, id) => {
        await httpClient.get(`strategy/visions/${id}/objectifs`).then((response) =>
            commit('setVisionObjectifs', response.data)
        )
            .catch(error => error)
    }
    ,
    createVision: async ({ commit }, data) => {
        const response = await httpClient.post('strategy/visions', data)
            .catch(error => error)
        commit('setVisionSingle', response.data)

    },
    deleteVision: async ({ commit }, id) => {
        await httpClient.delete(`strategy/visions/${id}`)
            .catch(error => error)
        commit

    },
    modifyVision: async ({ commit }, data) => {
        await httpClient.post('strategy/visions', data)
            .catch(error => error)
        commit

    },
    updateVision: async ({ commit }, data) => {
        const response = await httpClient.put(`strategy/visions/${data.id}`, data)
            .catch(error => error)

        actions.fetchVision({ commit }, data.id)
        commit('setVisionSingle', response.data)
    },
    fetchAllData: async ({ commit }) => {
        const response = await httpClient.get('strategy/visions')
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`strategy/visions?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })

        }
        commit('setVisions', allData);


    },
};

export default actions;