import { httpClient } from "@/helpers/services/HttpClient";
// import { concat } from "lodash";
import { FINANCE_FEUILLE_URL } from "@/helpers/constURL";
const actions = {

    createFinanceFeuille: async ({ commit }, data) => {
        await httpClient.post(FINANCE_FEUILLE_URL, data).then((response) => {
            commit('setFinanceFeuille', response.data)

        }).catch(error => error)
    },
    deleteFinanceFeuille: async ({ commit }, id) => {
        await httpClient.delete(`${FINANCE_FEUILLE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchFinanceFeuille: async ({ commit }, id) => {
        await httpClient.get(`${FINANCE_FEUILLE_URL}/${id}`).then(
            (response) =>
                commit('setFinanceFeuille', response.data)
        )
            .catch(error => error)

    },
    updateFinanceFeuille: async ({ commit }, data) => {
        await httpClient.put(`${FINANCE_FEUILLE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setFinanceFeuille', response.data)



            }).catch(error => error)
    }

};

export default actions;