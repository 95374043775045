const mutations = {
    setStatuses: (state, payload) => {
        state.statuses = payload;
    },
    setStatus: (state, payload) => {
        state.status = payload;
    },
    setStatusApplications: (state, payload) => {
        state.statusApplications = payload;
    },
    setStatusComites: (state, payload) => {
        state.statusComites = payload;
    },
    setStatusComposants: (state, payload) => {
        state.statusComposants = payload;
    },
    setStatusContributeurs: (state, payload) => {
        state.statusContributeurs = payload;
    },
    setStatusFeuilles: (state, payload) => {
        state.statusFeuilles = payload;
    },
    setStatusInitiatives: (state, payload) => {
        state.statusInitiatives = payload;
    },
    setStatusInscriptions: (state, payload) => {
        state.statusInscriptions = payload;
    },
    setStatusPhases: (state, payload) => {
        state.statusPhases = payload;
    },
    setStatusRefCards: (state, payload) => {
        state.statusRefCards = payload;
    },
    setStatusSemaines: (state, payload) => {
        state.statusSemaines = payload;
    },
    setStatusSessions: (state, payload) => {
        state.statusSessions = payload;
    },
    setStatusTasks: (state, payload) => {
        state.statusTasks = payload;
    },
    setStatusVisions: (state, payload) => {
        state.statusVisions = payload;
    },



};
export default mutations;