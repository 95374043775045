// import router from '@/router/index.js';


const tabs = {
  namespaced: true,
  state: () => ({
    activeTab: window.sessionStorage.tabIndex ? parseInt(window.sessionStorage.tabIndex) : 0,
    //for impact initiative tab
    impactInitiativeTab: window.sessionStorage.impactInitiativeTab ? parseInt(window.sessionStorage.impactInitiativeTab) : 0,
    //for données & GDPR tab
    dataApplicationTab: window.sessionStorage.dataApplicationTab ? parseInt(window.sessionStorage.dataApplicationTab) : 0,
    //for cadre tab
    cadreTab: window.sessionStorage.cadreTab ? parseInt(window.sessionStorage.cadreTab) : 0,
    //for entity tab
    entityTab: window.sessionStorage.entityTab ? parseInt(window.sessionStorage.entityTab) : 0,
    //for processus tab
    processusTab: window.sessionStorage.processusTab ? parseInt(window.sessionStorage.processusTab) : 0,
    //for vue applicative tab
    vueApplicativeTab: window.sessionStorage.vueApplicativeTab ? parseInt(window.sessionStorage.vueApplicativeTab) : 0,
    //for initiative tab
    initiativeTab: window.sessionStorage.initiativeTab ? parseInt(window.sessionStorage.initiativeTab) : 0,
    //for vue technique tab
    vueTechniqueTab: window.sessionStorage.vueTechniqueTab ? parseInt(window.sessionStorage.vueTechniqueTab) : 0,


  }),
  mutations: {
    CHANGE_TAB(state, payload) {
      state.activeTab = payload;
    },
    CHANGE_IMPACT_INITIATIVE_TAB(state, payload) {
      state.impactInitiativeTab = payload;
    },
    CHANGE_DONNES_APPLICATION_TAB(state, payload) {
      state.dataApplicationTab = payload;
    },
    CHANGE_CADRE_TAB(state, payload) {
      state.cadreTab = payload;
    },
    CHANGE_ENTITY_TAB(state, payload) {
      state.entityTab = payload;
    },
    CHANGE_PROCESSUS_TAB(state, payload) {
      state.processusTab = payload;
    },
    CHANGE_VUE_APPLICATIVE_TAB(state, payload) {
      state.vueApplicativeTab = payload;
    },
    CHANGE_INITIATIVE_TAB(state, payload) {
      state.initiativeTab = payload;
    },
    CHANGE_VUE_TECHNIQUE_TAB(state, payload) {
      state.vueTechniqueTab = payload;
    }


  },
  actions: {
    changeTab({ commit }, payload) {
      window.sessionStorage.setItem('tabIndex', payload);
      commit("CHANGE_TAB", payload);
    },
    changeImpactInitiativeTab({ commit }, payload) {
      window.sessionStorage.setItem('impactInitiativeTab', payload);
      commit("CHANGE_IMPACT_INITIATIVE_TAB", payload);
    },
    changeDataApplicationTab({ commit }, payload) {
      window.sessionStorage.setItem('dataApplicationTab', payload);
      commit("CHANGE_DONNES_APPLICATION_TAB", payload);
    },
    changeCadreTab({ commit }, payload) {
      window.sessionStorage.setItem('cadreTab', payload);
      commit("CHANGE_CADRE_TAB", payload);
    },
    changeEntityTab({ commit }, payload) {
      window.sessionStorage.setItem('entityTab', payload);
      commit("CHANGE_ENTITY_TAB", payload);
    },
    changeProcessusTab({ commit }, payload) {
      window.sessionStorage.setItem('processusTab', payload);
      commit("CHANGE_PROCESSUS_TAB", payload);
    },
    changeVueApplicativeTab({ commit }, payload) {
      window.sessionStorage.setItem('vueApplicativeTab', payload);
      commit("CHANGE_VUE_APPLICATIVE_TAB", payload);
    },
    changeInitiativeTab({ commit }, payload) {
      window.sessionStorage.setItem('initiativeTab', payload);
      commit("CHANGE_INITIATIVE_TAB", payload);
    },
    changeVueTechniqueTab({ commit }, payload) {
      window.sessionStorage.setItem('vueTechniqueTab', payload);
      commit("CHANGE_VUE_TECHNIQUE_TAB", payload);
    }
  },
  getters: {},
};

export default tabs;
