import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { GAIN_URL } from '@/helpers/constURL'

const actions = {
    fetchAllGains: async ({ commit }, page = GAIN_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${GAIN_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setGains', allData);
    },
    createGain: async ({ commit }, data) => {
        await httpClient.post(GAIN_URL, data).then((response) => {
            commit('setGain', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteGain: async ({ commit }, id) => {
        const response = await httpClient.delete(`${GAIN_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchGain: async ({ commit }, id) => {
        await httpClient.get(`${GAIN_URL}/${id}`).then(
            (response) =>
                commit('setGain', response.data)

        )
            .catch(error => error)

    },
    updateGain: async ({ commit }, data) => {
        await httpClient.put(`${GAIN_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)

            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;