const routerHistory = {
    namespaced: true,
    state: () => ({
        previousRoute: window.sessionStorage.previousRoute ? window.sessionStorage.previousRoute : "",
        //for capacite mitier active view
    }),
    mutations: {
        changePreviousRoute(state, payload) {
            state.previousRoute = payload;
        },
    },
    actions: {
        changePreviousRoute({ commit }, payload) {
            window.sessionStorage.setItem('previousRoute', payload);
            commit("changePreviousRoute", payload);
        },
    },
    getters: {
        PREVIOUS_ROUTE: state => state.previousRoute,
        fromCreation: state => state.previousRoute.includes("add"),

    },
};

export default routerHistory;
