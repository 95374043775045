
import Header from '@/layout/partials/ErrorsHeader';
const errors = {
    path: "",
    component: Header,
    redirect: "/404",
    name: "404",
    meta: {
        title: "404",
        navigationName: "404",
        iconClass: "mdi-chart-arc",
        requiresAuth: true,

    },
    children: [
        {
            path: "401",
            name: "401",
            component: () => import("@/pages/error/error-401"),

            meta: {
                title: "401",
                navigationName: "401",
                iconClass: "mdi mdi-format-list-checks ",
            },
        },
        {
            path: "403",
            name: "403",
            component: () => import("@/pages/error/error-403"),

            meta: {
                title: "403",
                navigationName: "403",
                iconClass: "mdi mdi-format-list-checks ",
            },
        },
        {
            path: "404",
            name: "404",
            component: () => import("@/pages/error/error-404"),
            meta: {
                title: "404",
                navigationName: "404",
                iconClass: "mdi mdi-monitor",
            },
        },
        {
            path: "500",
            name: "500",
            component: () => import("@/pages/error/error-500"),

            meta: {
                title: "500",
                navigationName: "500",
                iconClass: "mdi mdi-format-list-checks ",
            },
        },


    ]
};

export default errors;
