import { filterRequestData, httpClient } from "@/helpers/services/HttpClient";
import { TRANSFORMATION_PLAN_URL } from '@/helpers/constURL'

const actions = {
    fetchAllTransformationPlan: async ({ commit }) => {
        const response = await httpClient.get(TRANSFORMATION_PLAN_URL)
        const data = filterRequestData(response);
        commit('setTransformationPlan', data);
    },
    createTransformationPlan: async ({ commit }, data) => {
        await httpClient.post(TRANSFORMATION_PLAN_URL, data)
            .catch(error => error)
        commit


    },

};

export default actions;