import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { INITIATIVE_URL } from "@/helpers/constURL"

const actions = {
    fetchAllInitiatives: async ({ commit }, page = INITIATIVE_URL) => {
        var totalItems = 0
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${page}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        // window.console.log(allData)
        commit('setInitiatives', allData);
    },
    deleteInitiative: async ({ commit }, id) => {
        const response = await httpClient.delete(`${INITIATIVE_URL}/${id}`).then(() => {
            // this.fetchAllInitiatives(commit)
        }).catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)
    },
    createInitiative: async ({ commit }, data) => {
        const response = await httpClient.post(INITIATIVE_URL, data)
            .catch(error => error)
        window.console.log(commit)
        // window.console.clear()
        // window.console.log(response)
        commit('setInitiative', response.data)

        return response.data;

    },
    fetchInitiative: async ({ commit }, id) => {
        await httpClient.get(`${INITIATIVE_URL}/${id}`).then((response) => {
            commit('setInitiative', response.data)

        })
            .catch(error => error)

    },
    updateInitiative: async ({ commit }, data) => {

        await httpClient.put(`${INITIATIVE_URL}/${data.id}`, data).then((response) => {
            commit('setInitiative', response.data)

        })
            .catch(error => error)

        window.console.clear()


    },
    fetchInitiativeComites: async ({ commit }, id) => {
        await httpClient.get(`${INITIATIVE_URL}/${id}/comite`).then((response) => {
            commit('setInitiativeComite', response.data);
        })
    },

    fetchInitiativeImputations: async ({ commit }, id) => {
        if (!id) return;
        await httpClient.get(`${INITIATIVE_URL}/${id}/imputations`).then((response) => {
            commit('setInitiativeImputations', response.data);
        })
    },
    fetchInitiativeTasks: async ({ commit }, id) => {
        if (!id) return;
        await httpClient.get(`${INITIATIVE_URL}/${id}/tasks`).then((response) => {
            commit('setInitiativeTasks', response.data);
        })
    },

    fetchInitiativeImpacts: async ({ commit }, id) => {
        if (!id) return;
        await httpClient.get(`${INITIATIVE_URL}/${id}/impacts`).then((response) => {
            commit('setInitiativeImpacts', response.data);
        })
    },
    fetchImputationsByDirections: async ({ commit }, id) => {
        var totalItems = 0
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${INITIATIVE_URL}/${id}/imputations/directions?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setImputationsByDirections', allData);


    },

};

export default actions;