import Vuex from "vuex";
import Vue from "vue";

import tabs from "./global/tabs";
import views from "./global/views";
import loader from "./global/loader";
import visionModule from "./modules/strategy/vision";
import objectifModule from "./modules/strategy/objectif";
import couvertureModule from "./modules/strategy/couverture";
import dashboardModule from './modules/acceuil/dashboard';
import userModule from './modules/users';
import fdrModule from './modules/transformation/fdr'
import statusModule from './modules/communication'
import InitiativeModule from './modules/transformation/initiative'
import organisationModule from './modules/referentiel/organisation'
import entityModule from './modules/referentiel/entity';
import TaskModule from './modules/transformation/task'
import TypeModule from './modules/type'
import LivrableModule from './modules/transformation/livrable';
import PhaseModule from './modules/transformation/phase'
import transformationPlanModule from './modules/transformation/transformationPlan'
import RoleModule from './modules/role'
import applicationModule from './modules/referentiel/application';
import applicationComite from "./modules/gouvernance/comite";
import cadreModule from './modules/referentiel/cadre';
import patrimoineModule from "./modules/transformation/patrimoine";
import planningModule from "./modules/gouvernance/planification";
import manangeComite from "./modules/gouvernance/manage-committee"
import ImpactModule from "./modules/transformation/impact";
import teams from "./modules/acceuil/team"
import dossierModule from "./modules/gouvernance/dossier";
import ImputationModule from "./modules/transformation/imputation";
import AjustementModule from "./modules/financial/ajustement";
import auth from "./modules/auth/auth";
import GainModule from "./modules/transformation/gain";
import PatternModule from "./modules/transformation/patterns";
import CapaciteModule from "./modules/referentiel/capacite";
import CapaciteSiModule from "./modules/referentiel/capacite_si";
import ComposantModule from "./modules/referentiel/composant";
import CapaciteTechniqueModule from "./modules/referentiel/capacite_technique";
import ProcessusMetierModule from "./modules/referentiel/ProcessusMetier";
import CapaciteMetierModule from "./modules/referentiel/CapaciteMetier";
import conceptModule from "./modules/referentiel/concept";
import objetModule from "./modules/referentiel/objet";
import BeneficiaresModule from "./modules/referentiel/Beneficiare";
import attributModule from "./modules/referentiel/attribut";
import BusinessLineModule from "./modules/referentiel/businessLine";
import EditeurModule from "./modules/referentiel/editeur";
import VersionModule from "./modules/referentiel/version";
import principeModule from "./modules/referentiel/principe";
import FinanceFileModule from "./modules/transformation/fileFinance";
import FonctionModule from "./modules/referentiel/fonction";
import RefPhaseModule from "./modules/configuration/refPhase";
import DecisionModule from "./modules/gouvernance/decision";
import InscriptionModule from "./modules/gouvernance/inscription";
import InviteModule from "./modules/gouvernance/invite";
import CadrsFileModule from "./modules/referentiel/fileCadrs"
import KpiModule from "./modules/referentiel/kpi";
import ReferentModule from "./modules/referentiel/referent";
import SemaineModule from "./modules/transformation/semaine";
import ApiModule from "./modules/referentiel/API";
import FluxeModule from "./modules/referentiel/Fluxe";
import FinanceFeuilleModule from "./modules/transformation/financeFeuille";
import FinanceInitiativeModule from "./modules/transformation/financeInitiative";
import ConsommationModule from "./modules/financial/consomation";
import PorteModule from "./modules/configuration/porte";
import CurrencyModule from "./modules/configuration/currency";
import cStatusModule from "./modules/configuration/status";
import cRoleModule from "./modules/configuration/role";
import cTypeModule from "./modules/configuration/type";
import contributionModule from "./modules/referentiel/contribution";
import TraitementModule from "./modules/referentiel/traitement";
import routerHistory from "./global/router";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dummy: 'Hello dummy data'
  },
  getters: {
    DUMMY: state => state.dummy
  },
  modules: {
    routerHistory,
    views,
    tabs,
    loader,
    vision: visionModule,
    dashboard: dashboardModule,
    users: userModule,
    objectif: objectifModule,
    couverture: couvertureModule,
    fdr: fdrModule,
    status: statusModule,
    initiative: InitiativeModule,
    organisation: organisationModule,
    entity: entityModule,
    task: TaskModule,
    type: TypeModule,
    phase: PhaseModule,
    transformationPlan: transformationPlanModule,
    role: RoleModule,
    application: applicationModule,
    cadre: cadreModule,
    livrable: LivrableModule,
    patrimoine: patrimoineModule,
    comite: applicationComite,
    impact: ImpactModule,
    teams: teams,
    planning: planningModule,
    manage: manangeComite,
    dossier: dossierModule,
    imputation: ImputationModule,
    auth: auth,
    ajustement: AjustementModule,
    gain: GainModule,
    pattern: PatternModule,
    capacite: CapaciteModule,
    capaciteSi: CapaciteSiModule,
    capaciteTechnique: CapaciteTechniqueModule,
    composant: ComposantModule,
    processusMetier: ProcessusMetierModule,
    capaciteMetier: CapaciteMetierModule,
    concept: conceptModule,
    objet: objetModule,
    beneficiare: BeneficiaresModule,
    attribut: attributModule,
    businessLine: BusinessLineModule,
    editeur: EditeurModule,
    version: VersionModule,
    principe: principeModule,
    financeFile: FinanceFileModule,
    fonction: FonctionModule,
    refPhase: RefPhaseModule,
    decision: DecisionModule,
    inscription: InscriptionModule,
    invite: InviteModule,
    fileCadrs: CadrsFileModule,
    kpi: KpiModule,
    referent: ReferentModule,
    semaine: SemaineModule,
    Api: ApiModule,
    fluxe: FluxeModule,
    financeFeuille: FinanceFeuilleModule,
    financeInitiative: FinanceInitiativeModule,
    consommation: ConsommationModule,
    porte: PorteModule,
    currency: CurrencyModule,
    cStatus: cStatusModule,
    cRole: cRoleModule,
    cType: cTypeModule,
    contribution: contributionModule,
    traitement: TraitementModule,

  }
});

export default store;
