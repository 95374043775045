import layout from "@/layout";

const home = {
  path: "/",
  component: layout,
  name: "dashboard",

  meta: {
    title: "Accueil",
    navigationName: "Accueil",
    iconClass: "mdi-home",
    requiresAuth: true,
  },
  children: [
    {
      path: "",
      name: "dashboard",
      component: () => import("@/pages/dashboard"),
      meta: {
        title: "Dashboard",
        navigationName: "Dashboard",
        iconClass: "mdi mdi-home",
        // requiresAuth: true,
      },
    },
    {
      path: "tasks",
      name: "tasks",
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Mes Tâches",
        navigationName: "Tâches",
        iconClass: "mdi mdi-file-tree",
      },
      children: [
        {
          path: "",
          name: "tasks",
          component: () => import("@/pages/home/tasks/Index"),
          meta: {
            title: "Tâches",
            navigationName: "",
            iconClass: "mdi mdi-file-tree",
          },
        },
        {
          path: "create",
          name: "add-task",
          component: () => import("@/pages/home/tasks/Add"),
          meta: {
            title: "Tâches",
            navigationName: "Création",
            iconClass: "mdi mdi-file-tree",
          },
        },
        {
          path: "edit",
          name: "edit-task",
          component: () => import("@/pages/home/tasks/Edit"),
          meta: {
            title: "Tâches",
            navigationName: "Modification",
            iconClass: "mdi mdi-file-tree",
          },
        },
        {
          path: "show/:slug-:id",
          name: "show-task",
          component: () => import("@/pages/home/tasks/Show"),
          meta: {
            title: "Tâches",
            navigationName: "Consultation",
            iconClass: "mdi mdi-file-tree",
          },
        },
      ],
    },
  ],
};

export default home;
