const mutations = {

    setSessions:(state, payload,) => {
        state.sessions = payload;

    },
    setStatuts:(state, payload,) => {
        state.status = payload;

    },
    setSession:(state, payload,) => {
        state.session = payload;

    },
    setInscriptions:(state, payload,) => {
        state.Inscriptions = payload;

    },
    setInscription:(state, payload,) => {
        state.Inscription = payload;

    }
}
export default mutations;