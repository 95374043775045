import layout from "@/layout";

const companyStrategy = {
  path: "/strategy",
  name: "visions",

  component: layout,
  redirect: "/strategy/visions",
  meta: {
    title: "Strategie d'entreprise",
    navigationName: "Stratégie d'entreprise",
    iconClass: "mdi-lightbulb-on-outline",
    requiresAuth: true,

  },
  children: [
    {
      path: "visions",
      props: true,
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Visions",
        navigationName: "Visions",
        // iconClass: "mdi mdi-firework",
        iconClass: "mdi mdi-lightbulb-on-outline",

      },
      children: [
        {
          path: "",
          name: "visions",
          component: () => import("@/pages/company-strategy/vision/Index"),
          meta: {
            title: "Visions",
            navigationName: "",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-lightbulb-on-outline",

          },
        },
        {
          path: "create",
          name: "add-vision",
          component: () => import("@/pages/company-strategy/vision/Add"),
          meta: {
            title: "Visions",
            navigationName: "Création",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-lightbulb-on-outline",

          },
        },
        {
          path: "edit",
          name: "edit-vision",
          component: () => import("@/pages/company-strategy/vision/Edit"),
          meta: {
            title: "Visions",
            navigationName: "Modification",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-lightbulb-on-outline",

          },
        },
        {
          path: "show/:slug-:id",
          name: "show-vision",
          component: () => import("@/pages/company-strategy/vision/Show"),
          meta: {
            title: "Visions",
            navigationName: "Consultation",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-lightbulb-on-outline",

          },
        },
        {
          path: "print/:slug-:id",
          name: "print-vision",
          component: () => import("@/pages/company-strategy/vision/Print"),
          meta: {
            title: "Visions",
            navigationName: "Print",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-lightbulb-on-outline",

          },
        },
      ],
    },
    
    {
      path: "goals",
      props: true,
      component: {
        render: (c) => c("router-view"),
      },
      meta: {
        title: "Objectifs",
        navigationName: "Objectifs",
        // iconClass: "mdi mdi-strategy",
        iconClass: "mdi mdi-bullseye-arrow",

      },
      children: [
        {
          path: "",
          name: "goals",
          component: () => import("@/pages/company-strategy/goals/Index"),
          meta: {
            title: "Objectifs",
            navigationName: "",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-bullseye-arrow",


          },
        },
        {
          path: "show/:slug-:id",
          name: "show-objectif",
          component: () => import("@/pages/company-strategy/goals/Show"),
          meta: {
            title: "Objectifs",
            navigationName: "Consultation",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-bullseye-arrow",

          },
        },
        {
          path: "add",
          name: "add-objectif",
          component: () => import("@/pages/company-strategy/goals/Add"),
          meta: {
            title: "Objectifs",
            navigationName: "Création",
            // iconClass: "mdi mdi-firework",
            iconClass: "mdi mdi-bullseye-arrow",

          },
        },

      ],
    },
  ],
};

export default companyStrategy;
