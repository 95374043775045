<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header @sideBarChange="sideBarChange" />
      <div class="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          class="ml-auto"
          :class="{ 'max-width': sidebarOpen, 'main-panel': !sidebarOpen }"
        >
          <!-- <div class="main-panel ml-auto max-width"> -->
          <div class="content-wrapper" style="min-height: calc(100vh - 145px)">
            <div class="page-header row">
              <div class="col-12 col-md-8 d-flex flex-wrap px-0">
                <h3 class="page-title my-2">
                  <span class="page-title-icon bg-gradient-primary text-white mr-2">
                    <i :class="$route.meta.iconClass"></i>
                  </span>

                  {{
                    $route.meta.title === "Sécurité" ||
                    $route.meta.title === "Projet" ||
                    $route.meta.title === "Patrimoine"
                      ? $route.meta.iconClass === "mdi mdi-database"
                        ? "Patrimoine"
                        : "Analytiques"
                      : $route.meta.title
                  }}
                </h3> 
                <div class="pl-3 my-2">
                  <BreadCrumbs class="bg-white rounded-sm text-gray mb-0" />
                </div>
              </div>
              <div
                class="col-12 col-md-4 d-flex flex-wrap align-items-center justify-content-end px-0 my-2"
              >
                <b-button
                  v-if="$route.fullPath !== '/'"
                  variant="light"
                  size="sm"
                  @click="$router.go(-1)"
                  ><i class="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                </b-button>
              </div>
            </div>

            <router-view></router-view>
          </div>
          <!-- content wrapper ends -->
          <Footer />
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    <!-- <Modal /> -->
  </section>
</template>

<script>
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
// import Modal from "../components/custom/Modal.vue";
import BreadCrumbs from "@/components/custom/BreadCrumbs";
export default {
  name: "layout",
  components: {
    Header,
    Sidebar,
    Footer,
    BreadCrumbs,
  },
  data() {
    return { open: window.sessionStorage.sidebarOpen };
  },
  mounted() {
    window.sessionStorage.setItem("sidebarOpen", false);
    // this.open = false;
  },
  computed: {
    sidebarOpen() {
      return this.open === "false";
    },
  },

  methods: {
    sideBarChange(status) {
      this.open = status;
    },
    // scrollEndAllTables() {
    //   const navElements = this.$el.querySelectorAll(".nav-link");
    //   for (const navElement of navElements) {
    //     navElement.addEventListener("click", () => {
    //       const tables = this.$el.querySelectorAll(".table-responsive");
    //       for (const element of tables) {
    //         element.scrollLeft = element.scrollWidth + 9999;
    //       }
    //     });
    //   }
    // },
  },
  watch: {
    // $route() {
    //   setTimeout(() => {
    //     this.scrollEndAllTables();
    //   }, 0);
    // },
  },
};
</script>
<style>
.max-width {
  width: calc(100% - 70px) !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
