const mutations = {
    setObjectif: (state, payload) => {
        state.objectifs = payload;
    },
    setobj: (state, payload) => {
        state.task = payload;
    },
    setObjectifSingle: (state, payload) => {
        state.objectif = payload;
    },
    setFdrs : (state,payload) => {
        state.fdrs = payload
    }
}
export default mutations;