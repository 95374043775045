import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { REFERENT_URL } from "@/helpers/constURL"
const actions = {


    fetchAllReferents: async ({ commit }, page = REFERENT_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${REFERENT_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        window.console.log(allData)
        commit('setReferents', allData);
    },
    createReferent: async ({ commit }, data) => {
        await httpClient.post(REFERENT_URL, data).then((response) => {
            commit('setReferent', response.data)
            window.console.log(response)


        }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    deleteReferent: async ({ commit }, id) => {
        const response = await httpClient.delete(`${REFERENT_URL}/${id}`)
            .catch(error => error)

        window.console.log(commit)
        window.console.clear()
        window.console.log(response)


    },
    fetchReferent: async ({ commit }, id) => {
        await httpClient.get(`${REFERENT_URL}/${id}`).then(
            (response) =>
                commit('setReferent', response.data)

        )
            .catch(error => error)

    },
    updateReferent: async ({ commit }, data) => {
        await httpClient.put(`${REFERENT_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)
            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()

    }

};

export default actions;