import AuthService from "@/helpers/services/AuthService";
// import { alertService } from "../../../helpers/services/alertService";
import {
    ROLE_MANAGER,
    ROLE_COLLABORATEUR,
    ROLE_ARCHITECTE,
    ROLE_DEV,
    ROLE_PO,
    ROLE_GOUVERNANCE,
    ROLE_FINANCE,
    ROLE_ADMIN,
    ROLE_SUPER
} from "@/helpers/constants";
import { httpClient } from "@/helpers/services/HttpClient";

const verifyRole = (state, role) => state.payload && state.payload.roles.includes(role);

const state = {
    token: localStorage.getItem('token') || '',
    user: null,
    status: null,
    payload: null,
    refreshToken: localStorage.getItem('refreshToken') || '',
    error: null
};

const getters = {
    isLoggedIn: state => !!state.token,
    isManager: state => verifyRole(state, ROLE_MANAGER),
    isCollaborator: state => verifyRole(state, ROLE_COLLABORATEUR),
    isArchitect: state => verifyRole(state, ROLE_ARCHITECTE),
    isDev: state => verifyRole(state, ROLE_DEV),
    isPo: state => verifyRole(state, ROLE_PO),
    isGouvernance: state => verifyRole(state, ROLE_GOUVERNANCE),
    isFinance: state => verifyRole(state, ROLE_FINANCE),
    isAdmin: state => verifyRole(state, ROLE_ADMIN),
    isSuper: state => verifyRole(state, ROLE_SUPER),
    authStatus(state) {
        return state.status;
    },
    getUser(state) {
        return state.user;
    },
    payload: state => state.payload,
    AuthenticatedUser: state => state.user
};

const mutations = {
    set_token: (state, token) => {
        localStorage.setItem('token', token);
        state.token = token
    },
    set_payload: (state, payload) => state.payload = payload,
    auth_request(state) {
        state.status = 'loading';
    },
    auth_success(state, obj) {
        state.status = 'success';
        state.token = obj.token;
        state.user = obj.user;
    },
    auth_error(state, error) {
        state.status = 'error';
        state.error = error;
    },
    set_refreshToken: (state, refreshToken) => {
        localStorage.setItem('refreshToken', refreshToken);
        state.refreshToken = refreshToken
    },
    logout(state) {
        state.status = '';
        state.token = '';
        state.refreshToken = '';
        state.payload = null
        state.user = null
    },
    set_user: (state, user) => state.user = user
};

const actions = {
    login({ commit }, user) {
        commit('auth_request');
        return AuthService.login(user)
            .then(({ token, refresh_token, payload }) => {
                commit('set_token', token);
                commit('set_payload', payload);
                commit('set_refreshToken', refresh_token)
            }).catch((error) => {
                commit('auth_error', error);
                throw error;
                // throw new Error('Authentication failed');
            }
            ).finally(() => {
                commit('auth_error');
            })
    },
    logout({ commit }) {
        return new Promise((resolve) => {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            //delete this.axios.defaults.headers.common['Authorization'];
            resolve()
        })
    },
    refreshPayload({ state, commit }) {
        const payload = AuthService.decodePayload(state.token);
        commit('set_payload', payload);
    },
    fetchUser({ commit }) {
        if (state.payload && state.payload.id) {
            return httpClient.get(`/users/${state.payload.id}`)
                .then((response) => {
                    commit('set_user', response.data)
                    return response
                }).catch((error) => (error))
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};