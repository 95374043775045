import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { KPI_URL } from "@/helpers/constURL"
const actions = {
    fetchAllKpis: async ({ commit }, page = KPI_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${KPI_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setKpis', allData);
    },
    createKpi: async ({ commit }, data) => {
        await httpClient.post(KPI_URL, data).then((response) => {
            commit('setKpi', response.data)

        }).catch(error => error)
    },
    deleteKpi: async ({ commit }, id) => {

        await httpClient.delete(`${KPI_URL}/${id}`)
            .catch(error => error)
        commit

    },
    fetchKpi: async ({ commit }, id) => {
        await httpClient.get(`${KPI_URL}/${id}`).then(
            (response) =>
                commit('setKpi', response.data)

        )
            .catch(error => error)

    }, updateKpi: async ({ commit }, data) => {
        await httpClient.put(`${KPI_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)
            }).catch(error => error)
        window.console.log(commit)

    },
};

export default actions;