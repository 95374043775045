import { httpClient } from "../../../../helpers/services/HttpClient";
import { concat } from "lodash";
import { COMPOSANT_URL, COMPOSANT_FILE_URL } from '@/helpers/constURL'
const actions = {
    fetchAllComposants: async ({ commit }, page = COMPOSANT_URL) => {

        const response = await httpClient.get(page)
        const totalItems = response.data['hydra:totalItems'];
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${COMPOSANT_URL}?page=${i}`).then((response) => {
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setComposants', allData);
    },
    createComposant: async ({ commit }, data) => {
        await httpClient.post(COMPOSANT_URL, data).then((response) => {
            commit('setComposant', response.data)


        }).catch(error => error)
        window.console.clear()
    },
    deleteComposant: async ({ commit }, id) => {
        await httpClient.delete(`${COMPOSANT_URL}/${id}`)
            .catch(error => error)
        commit

    },
    fetchComposant: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}`).then(
            (response) =>
                commit('setComposant', response.data)

        )
            .catch(error => error)

    },
    fetchComposantVersions: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}/versions`).then(
            (response) =>
                commit('setComposantVersions', response.data)

        )
            .catch(error => error)

    },
    updateComposant: async ({ commit }, data) => {
        await httpClient.put(`${COMPOSANT_URL}/${data.id}`, data)
            .then((response) => {
                window.console.log(response)
            }).catch(error => error)
        window.console.log(commit)
        window.console.clear()
    },
    fetchComposantTransformations: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}/transformation`).then(
            (response) =>
                commit('setComposantTransformation', response.data)

        )
            .catch(error => error)

    },
    fetchComposantApplications: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}/applications`).then(
            (response) =>
                commit('setComposantApplications', response.data)

        )
            .catch(error => error)

    },
    fetchComposantCapacites: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}/capacites`).then(
            (response) =>
                commit('setComposantCapacites', response.data)

        )
            .catch(error => error)

    },

    fetchComposantCadres: async ({ commit }, id) => {
        await httpClient.get(`${COMPOSANT_URL}/${id}/cadres`).then(
            (response) =>
                commit('setComposantCadres', response.data)

        )
            .catch(error => error)

    },
    createFileComposant: async ({ commit }, data) => {
        await httpClient.post(COMPOSANT_FILE_URL, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => commit)
            .catch(error => error)

    },

    deleteFileComposant: async ({ commit }, id) => {
        await httpClient.delete(`${COMPOSANT_FILE_URL}/${id}`).then(() => commit)
            .catch(error => error)

    },

};

export default actions;