import { httpClient } from "@/helpers/services/HttpClient";
import { concat } from "lodash";
import { ROLE_URL, REFERENT_ROLE_URL } from "@/helpers/constURL";
const actions = {
    fetchAllRoles: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${ROLE_URL}?page=${i}`).then((response) => {
                totalItems = response.data['hydra:totalItems'];
                allData = concat(allData, response.data['hydra:member'])
            })
        }
        commit('setRoles', allData);
    },
    createRole: async ({ commit }, data) => {
        // var r =  'configuration/role_referents';
        await httpClient.post(ROLE_URL, data).then((response) => {
            commit('setRole', response.data)

        }).catch(error => error)
    },
    deleteRole: async ({ commit }, id) => {
        await httpClient.delete(`${ROLE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchRole: async ({ commit }, id) => {
        await httpClient.get(`${ROLE_URL}/${id}`).then(
            (response) =>
                commit('setRole', response.data)
        )
            .catch(error => error)

    },
    updateRole: async ({ commit }, data) => {
        await httpClient.put(`${ROLE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setRole', response.data)



            }).catch(error => error)
    },
    fetchAllReferentRoles: async ({ commit }) => {
        var totalItems = 0;
        var allData = []
        for (let i = 1; i <= (totalItems / 10) + 1; i++) {
            await httpClient.get(`${REFERENT_ROLE_URL
                }?page=${i}`).then((response) => {
                    totalItems = response.data['hydra:totalItems'];
                    allData = concat(allData, response.data['hydra:member'])
                })
        }
        commit('setReferentRoles', allData);
    },
    createReferentRole: async ({ commit }, data) => {
        await httpClient.post(REFERENT_ROLE_URL, data).then((response) => {
            commit('setReferentRole', response.data)

        }).catch(error => error)
    },
    deleteReferentRole: async ({ commit }, id) => {
        await httpClient.delete(`${REFERENT_ROLE_URL}/${id}`)
            .catch(error => error)
        commit
    },
    fetchReferentRole: async ({ commit }, id) => {
        await httpClient.get(`${REFERENT_ROLE_URL}/${id}`).then(
            (response) =>
                commit('setReferentRole', response.data)
        )
            .catch(error => error)

    },
    updateReferentRole: async ({ commit }, data) => {
        await httpClient.put(`${REFERENT_ROLE_URL}/${data.id}`, data)
            .then((response) => {

                commit('setReferentRole', response.data)

            }).catch(error => error)
    }

};

export default actions;